import React, { useState } from 'react';
import { Box, Divider, Typography, SxProps, Theme } from '@mui/material';
import MCQQuestion from './MCQuestion';
import MCTFQuestion from './MCTFQuestion';
import SAQuestion from './SAQuestion';
import { Question } from './type';
import { renderHTMLContent, renderHTMLContent2 } from './extractQuestions';
import useResponsive from '../../hooks/useResponsive';

// Props for the Question component
interface QuestionProps {
  question: Question;
  editable?: boolean;
  sx?: SxProps<Theme>;
  onAnswerChange?: (answer: string) => void;
  answer?: string;
  formatSize?: number;
}

const QuestionComponent: React.FC<QuestionProps> = ({
  question,
  editable,
  sx,
  onAnswerChange,
  answer,
  formatSize = 1,
}) => {
  // Conditional rendering based on question type
  const renderQuestionType = () => {
    switch (question.type) {
      case 'mcq':
        return (
          <MCQQuestion
            question={question}
            editable={editable}
            onAnswerChange={onAnswerChange}
            answer={answer}
            formatSize={formatSize}
          />
        );
      case 'mctf':
        return (
          <MCTFQuestion
            question={question}
            editable={editable}
            onAnswerChange={onAnswerChange}
            answer={answer}
            formatSize={formatSize}
          />
        );
      case 'sa':
        return (
          <SAQuestion
            question={question}
            editable={editable}
            onAnswerChange={onAnswerChange}
            answer={answer}
            formatSize={formatSize}
          />
        );
      default:
        return <Typography variant="body1">Unknown question type</Typography>;
    }
  };
  const isMobile = useResponsive('down', 'md');

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth, naturalHeight } = e.currentTarget;
    setImageSize({ width: naturalWidth, height: naturalHeight });
  };

  return (
    <Box
      sx={{
        // maxHeight: '40vh',
        // maxHeight: '40vh',
        // overflow: 'auto',
        scrollbarWidth: 'none', // Firefox
        msOverflowStyle: 'none', // IE and Edge
        '&::-webkit-scrollbar': {
          display: isMobile ? 'none' : 'none', // Webkit
        },
        px: 1,
        maxWidth: '100%',
        fontSize: formatSize + 'rem',

        // Hide the scrollbar for mobile devices
        // Webkit is used for Chrome, Safari, and Opera
        // scrollbarWidth is used for Firefox

        ...sx, // Merge the optional styles with the default styles
      }}
    >
      <Typography variant="examText" gutterBottom>
        <strong> {question.section} </strong>
        {renderHTMLContent(question.sectionContent || '')}
      </Typography>

      <Divider
        sx={{
          my: 1,
          backgroundColor: 'text.secondary',
          opacity: 0.3,
        }}
      />

      <Box
        sx={{
          position: 'relative',
          minHeight:
            question.type === 'mctf' && question.questionImageUrl
              ? imageSize.height * formatSize + 20
              : 'auto',
        }}
      >
        {question.questionImageUrl && (
          <Box
            component="img"
            src={question.questionImageUrl}
            alt={`Question ${question.index}`}
            onLoad={handleImageLoad}
            sx={{
              float: 'right',
              marginLeft: 2,
              marginBottom: 2,
              width: imageSize.width * formatSize + 'px',
              height: 'auto',
            }}
          />
        )}

        <Typography variant="examText">
          <strong>Câu {question.index}:</strong>{' '}
          {renderHTMLContent(question.question)}
        </Typography>
      </Box>

      {/* Render the appropriate question type */}
      {renderQuestionType()}

      {!editable && (
        <>
          <Divider
            sx={{
              my: 1,
              backgroundColor: 'text.secondary',
              opacity: 0.3,
            }}
          />

          <Box
            sx={{
              position: 'relative',
            }}
          >
            {question.explainImageUrl && (
              <Box
                component="img"
                src={question.explainImageUrl}
                alt={`Question ${question.index}`}
                onLoad={handleImageLoad}
                sx={{
                  float: 'right',
                  marginLeft: 2,
                  marginBottom: 2,
                  width: imageSize.width * formatSize + 'px',
                  height: 'auto',
                }}
              />
            )}

            {renderHTMLContent2(question.explain)}
          </Box>

          {/* {question.explainImageUrl && (
            <Box mt={2}>
              <img
                src={question.explainImageUrl}
                alt={`Answer for question ${question.index}`}
              />
            </Box>
          )}

          {renderHTMLContent2(question.explain)} */}
        </>
      )}
    </Box>
  );
};

export default QuestionComponent;
