import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../pages/Layout';
import paths from '../constants/paths';
import HomePage from '../pages/Home';
import NotFoundPage from '../pages/Error/NotFoundPage';
import CoursesPage from '../pages/Courses';
import ExamStepper from '../pages/Exams';
import ExammingPage from '../pages/Exams/ExammingPage';

export default function MyRouter() {
  return (
    <Routes>
      {/* Routes that should not use Layout */}
      <Route path={paths.EXAMMING} element={<ExammingPage />} />

      {/* Routes that should use Layout */}
      <Route element={<Layout />}>
        <Route path={paths.HOME} element={<HomePage />} />
        <Route path={paths.MYCOURSE} element={<HomePage />} />
        <Route path={paths.COURSE} element={<CoursesPage />} />
        <Route path={paths.ANSWER} element={<ExamStepper />} />
        <Route path={paths.LEADERBOARD} element={<ExamStepper />} />
        {/* Catch-all for 404 */}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}
