import { useEffect } from 'react';
import './App.css';
import MyRouter from './routes';
import { getMe, getStudent, getTime } from './api';
import { useAppDispatch } from './hooks/redux';
import { authActions } from './redux/slices/authSlice';
import React from 'react';
import dayjs from 'dayjs';
import { appActions } from './redux/slices/appSlice';
import '@fontsource/open-sans'; // Defaults to weight 400
import '@fontsource/lato'; // Defaults to regular weight (400)
import '@fontsource/oswald/500.css'; // Defaults to regular weight (400)
import '@fontsource/freeman/400.css'; // Defaults to regular weight (400)
import '@fontsource/montserrat/400.css'; // Regular weight
import '@fontsource/montserrat/500.css'; // Medium weight
import '@fontsource/montserrat/600.css'; // Semi-Bold weight
import '@fontsource/montserrat/700.css'; // Bold weight
import '@fontsource/raleway/300.css'; // Medium weight
import '@fontsource/raleway/400.css'; // Medium weight
import '@fontsource/raleway/500.css'; // Medium weight
import '@fontsource/raleway/600.css'; // Semi-Bold weight
import '@fontsource/raleway/700.css'; // Regular weight
import '@fontsource/raleway/800.css'; // Bold weight
import '@fontsource/cormorant-sc/700.css';

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchUser = async () => {
      // try {
      //   const token = localStorage.getItem('token');
      //   console.log('token', token);
      //   const { data: response } = await getMe();

      //   //dispatch(authActions.setUser({ user: response.data.data }));
      // } catch (err) {
      //   const storedUserData = localStorage.getItem('userId');
      //   if (storedUserData !== undefined && storedUserData !== null) {
      //     const userId = storedUserData;
      //     try {
      //       const { data: response } = await getStudent(userId);
      //       //dispatch(authActions.setUser({ user: response }));
      //     } catch (err) {
      //       console.log(err);
      //     }
      //   }
      // }
      const storedUserData = localStorage.getItem('userId');
      const storedToken = localStorage.getItem('token');
      if (storedUserData !== null && storedToken !== null) {
        const id = storedUserData;
        try {
          const { data: response } = await getStudent(id, storedToken);
          dispatch(authActions.setUser(response));
        } catch (err) {
          console.log(err);
        }
      }
    };
    const fetchTime = async () => {
      try {
        const { data: response } = await getTime();
        const currentDatetime = response.datetime;
        const timediff = dayjs(currentDatetime).diff(dayjs(), 'seconds');
        dispatch(appActions.setTimeDiff(timediff));
      } catch (error) {
        console.error('Error fetching current time:', error);
      }
    };
    fetchUser();
    fetchTime();
  }, [dispatch]);
  return <MyRouter />;
}

export default App;
