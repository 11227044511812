import React, { Fragment, useEffect } from 'react';
import Header from '../components/Layouts/Header';
import Footer from '../components/Layouts/Footer';
//import LoginModal from '../components/Auth/LoginModal';
import Notification from '../components/Notification';
//import RegisterModal from '../components/Auth/RegisterModal';
import useAuth from '../hooks/useAuth';
import OkCancelNotification from '../components/OKCancelNotification';
import AppDialogs from '../components/AppDialogs';
import styled from '@emotion/styled';
import LinkNotification from '../components/LinkNotification';
import { Box } from '@mui/material';
import NavDrawer from '../components/Layouts/NavDrawer';
import useResponsive from '../hooks/useResponsive';
import BottomNavBar from '../components/Layouts/BottomNavBar';
import LeftNavBar from '../components/Layouts/LeftNavBar';
import { Outlet } from 'react-router-dom';

const drawerWidth = 220;

const Layout: React.FC<{ children?: React.ReactNode }> = () => {
  const { user } = useAuth();
  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');

  const [openNav, setOpenNav] = React.useState<boolean>(
    () => (localStorage.getItem('openNav') === 'true' && isDesktop) || false
  );

  // Mobile
  const handleOpenNavMenu = () => {
    setOpenNav(true);
  };

  const handleCloseNavMenu = () => {
    setOpenNav(false);
  };

  useEffect(() => {
    localStorage.setItem('openNav', openNav.toString());
  }, [openNav]);

  return (
    <Fragment>
      {!isMobile ? (
        <NavDrawer
          openNav={openNav}
          handleCloseNavMenu={handleCloseNavMenu}
          handleOpenNavMenu={handleOpenNavMenu}
          drawerWidth={drawerWidth}
          isDesktop={isDesktop}
          isMobile={isMobile}
          user={user}
        />
      ) : (
        <BottomNavBar />
      )}

      <Header
        handleOpenNavMenu={handleOpenNavMenu}
        isDesktop={isDesktop}
        isMobile={isMobile}
      />
      <Box
        sx={{
          marginLeft: openNav && isDesktop ? `${drawerWidth}px` : '0px', // Change margin based on openNav state
          transition: 'margin-left 0.3s ease-in-out',
          mb: isMobile ? 9 : 0,
          mt: isMobile ? 6 : 0,
        }}
      >
        <Box display="flex" flexDirection="row">
          <Box display={isMobile || (openNav && isDesktop) ? 'none' : 'block'}>
            <LeftNavBar />
          </Box>

          <Box flexGrow={1}>
            <Outlet />
          </Box>
        </Box>
      </Box>

      <Footer />
      <Notification />
      <OkCancelNotification />
      <LinkNotification />
      {/* {!user && <LoginModal />} */}
      {/* {!user && <RegisterModal />} */}
      <AppDialogs />
    </Fragment>
  );
};

export default Layout;
