import React, { useEffect, useState } from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Box,
  Stack,
  Divider,
} from '@mui/material';
import { Question } from './type';
import { renderHTMLContent } from './extractQuestions';
import { yellow } from '@mui/material/colors';

// Props for the MTCQuestion component
interface MTCQuestionProps {
  question: Question;
  editable?: boolean;
  onAnswerChange?: (answer: string) => void;
  answer?: string;
  formatSize?: number;
}

interface CustomFormControlLabelProps {
  value: string;
  label: React.ReactNode;
  formatSize?: number;
}

const CustomFormControlLabel: React.FC<CustomFormControlLabelProps> = ({
  value,
  label,
  formatSize = 1,
}) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: formatSize + 0.5 + 'rem',
            },
            color: yellow[800],
            '&.Mui-checked': {
              color: yellow[700],
            },
          }}
        />
      }
      label={<Typography variant="examText">{label}</Typography>}
      sx={{ py: formatSize - 0.5 }}
    />
  );
};

const MTCQuestion: React.FC<MTCQuestionProps> = ({
  question,
  editable,
  onAnswerChange,
  answer,
  formatSize,
}) => {
  // Set the initial selected answer based on the question's correct answer
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');

  // Initialize answers based on the question's correct answers
  useEffect(() => {
    if (editable) {
      setSelectedAnswer(answer || '');
    } else {
      setSelectedAnswer(question.correctAnswer);
    }
  }, [question.correctAnswer, editable, answer]);

  // Handle change of selected answer
  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!editable) return;
    setSelectedAnswer(event.target.value);
    onAnswerChange && onAnswerChange(event.target.value);
  };
  return (
    <Box>
      {/* Render multiple choice options if the question type is 'mcq' */}
      {question.type === 'mcq' && (
        <RadioGroup value={selectedAnswer} onChange={handleAnswerChange}>
          <CustomFormControlLabel
            value="A"
            label={
              <>
                <strong>A.</strong> {renderHTMLContent(`${question.questionA}`)}
              </>
            }
            formatSize={formatSize}
          />
          <CustomFormControlLabel
            value="B"
            label={
              <>
                <strong>B.</strong> {renderHTMLContent(`${question.questionB}`)}
              </>
            }
            formatSize={formatSize}
          />
          <CustomFormControlLabel
            value="C"
            label={
              <>
                <strong>C.</strong> {renderHTMLContent(`${question.questionC}`)}
              </>
            }
            formatSize={formatSize}
          />
          <CustomFormControlLabel
            value="D"
            label={
              <>
                <strong>D.</strong> {renderHTMLContent(`${question.questionD}`)}
              </>
            }
            formatSize={formatSize}
          />
        </RadioGroup>
      )}
    </Box>
  );
};

export default MTCQuestion;
