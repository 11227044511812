import * as React from 'react';
import Box from '@mui/material/Box';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

const LeftNavBar: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get the current location

  const handleNavigation = (newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/'); // Redirect to "Trang chủ"
        break;
      case 1:
        navigate('/mycourse'); // Redirect to "Tôi"
        break;
      case 2:
        navigate('/subscriptions'); // Redirect to "Khoá đăng ký"
        break;
      default:
        break;
    }
  };

  const navItems = [
    { label: 'Trang chủ', icon: <HomeIcon />, index: 0, path: '/' },
    { label: 'Tôi', icon: <PersonIcon />, index: 1, path: '/mycourse' },
    {
      label: 'Đăng ký',
      icon: <SubscriptionsIcon fontSize="small" />,
      index: 2,
      path: '/subscriptions',
    },
  ];

  React.useEffect(() => {
    const currentIndex = navItems.findIndex(
      (item) => item.path === location.pathname
    );
    if (currentIndex !== -1) {
      setValue(currentIndex);
    }
  }, [location.pathname, navItems]);

  return (
    <Box
      sx={{
        width: 65, // Width similar to YouTube's sidebar
        display: 'flex',
        flexDirection: 'column', // Stack items vertically
        alignItems: 'center',
        height: '90vh',
      }}
    >
      {navItems.map((item) => (
        <Button
          key={item.index}
          onClick={() => handleNavigation(item.index)}
          sx={{
            color: value === item.index ? 'primary.main' : 'text.secondary',
            display: 'flex',
            flexDirection: 'column', // Icon on top, text below
            padding: '10px 0',
            my: 1,
            borderRadius: 1,
            '&:hover': {
              backgroundColor: 'background.paper', // Hover effect
            },
          }}
        >
          {item.icon}
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: 8,
              fontWeight: value === item.index ? 'bold' : 'normal',
              textTransform: 'none',
            }}
          >
            {item.label}
          </Typography>
        </Button>
      ))}
    </Box>
  );
};

export default LeftNavBar;
