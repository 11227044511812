import React, { useState, useEffect } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  Tabs,
  Tab,
  CircularProgress,
} from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ExamPage from './ExammingPage';
import { defaultExam, defaultExamInput, Exam, ExamInput } from './type';
import {
  addQuestion,
  createNewExam,
  deleteQuestionsByExamId,
  fetchExamById,
  updateExamById,
  updateQuestionById,
} from '../../api';
import dayjs from 'dayjs';
import AnswerResultPage from './AnswerResultPage';
import { useDispatch } from 'react-redux';
import {
  addCourseItem,
  updateCourseItem,
} from '../../redux/slices/courseItemSlice';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CloseIcon from '@mui/icons-material/Close';
import { appActions } from '../../redux/slices/appSlice';
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';

interface ExamTabsProps {
  examId?: string;
  mode?: 'view' | 'edit' | 'create';
  courseId?: string;
  parentFolderId?: string;
  closeDialog?: () => void;
  isTeacher?: boolean;
}

const fullSteps = [
  {
    label: 'Đề thi',
    icon: <AssignmentIcon />,
  },
  {
    label: 'Bảng xếp hạng',
    icon: <LeaderboardIcon />,
  },
];

const ExamTabs: React.FC<ExamTabsProps> = ({
  examId,
  mode = 'view',
  courseId = '',
  parentFolderId = '',
  closeDialog = () => {},
  isTeacher = false,
}) => {
  const { examId: urlExamId } = useParams<{ examId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [exam, setExam] = useState<Exam | null>(defaultExam);
  const [examInput, setExamInput] = useState(defaultExamInput);
  const { user } = useAuth();
  const [isDataFilled, setIsDataFilled] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const isMobile = useResponsive('down', 'md');

  useEffect(() => {
    setIsDataFilled(
      !(
        examInput.title &&
        examInput.duration &&
        examInput.subject &&
        examInput.questions.length > 0
      )
    );
  }, [examInput]);

  // const isTeacher =
  //   !!user &&
  //   (user.role === "admin" ||
  //     (user.role === "teacher" && exam?.createdBy._id === user._id));

  //Remove the text after the last slash
  const path = location.pathname.substring(
    0,
    location.pathname.lastIndexOf('/')
  );

  const isNotOnDialog = path.includes('answer') || path.includes('leaderboard');

  const height = isNotOnDialog ? '90vh' : '80vh';

  // Fetch exam data based on the examId
  useEffect(() => {
    const loadExam = async () => {
      try {
        const id = examId || urlExamId;
        if (!id) return;

        const res = await fetchExamById(id);
        const data = res.data;
        console.log('Exam data:', data);
        setExam(data);

        setExamInput({
          title: data.title || '',
          duration: data.duration || '',
          subject: data.subject || '',
          executedAt: data.executedAt ? new Date(data.executedAt) : null,
          questions: data.questions || [],
          isExercise: data.isExercise || false,
          onlyForMembers: data.onlyForMembers || false,
        } as ExamInput);
      } catch (err) {
        console.error('Error fetching exam:', err);
      }
    };

    loadExam();
  }, [examId, urlExamId]);

  // Determine active tab based on URL
  useEffect(() => {
    if (location.pathname.includes('leaderboard')) {
      setActiveTab(1);
    } else if (location.pathname.includes('answer')) {
      setActiveTab(0);
    }
  }, [location.pathname]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    const id = examId || urlExamId;
    if (isNotOnDialog) {
      switch (newValue) {
        case 0:
          navigate(`/answer/${id}`);
          break;
        case 1:
          navigate(`/leaderboard/${id}`);
          break;
        default:
          break;
      }
    }
  };

  // Handle Save Button Click
  const handleSave = async () => {
    try {
      // Prepare updated exam data
      setLoadingButton(true);
      // Save to database
      if (mode === 'edit') {
        // Update existing exam
        if (!examId) return;
        const updatedItem = await updateExamById(examId, {
          ...examInput,
          questions: [],
        });
        const updatedItemWithType = {
          ...updatedItem.data,
          type: 'exam',
          itemId: updatedItem.data.examId,
        };
        if (examInput.isEdittingQuestion) {
          console.log('Editting question');
          await deleteQuestionsByExamId(examId);
          // Post all questions to server
          const postQuestions = async () => {
            for (const question of examInput.questions) {
              const questionData = new FormData();
              questionData.append('section', question.section);
              questionData.append('sectionContent', question.sectionContent);
              questionData.append('index', question.index.toString());
              questionData.append('type', question.type);
              questionData.append('correctAnswer', question.correctAnswer);
              questionData.append(
                'questionImageUrl',
                question.questionImageUrl
              );
              questionData.append('explainImageUrl', question.explainImageUrl);
              question.questionImage &&
                questionData.append('questionImage', question.questionImage);
              question.explainImage &&
                questionData.append('explainImage', question.explainImage);
              questionData.append('examId', examId);
              questionData.append('question', question.question);
              questionData.append('explain', question.explain);
              questionData.append('questionA', question.questionA);
              questionData.append('questionB', question.questionB);
              questionData.append('questionC', question.questionC);
              questionData.append('questionD', question.questionD);

              try {
                const newQuestion = await addQuestion(questionData);
                // Handle success, e.g., log or update state
              } catch (error) {
                // Handle error for each question
                console.error(
                  `Failed to add question ${question.index}:`,
                  error
                );
              }
            }
          };
          // Call the function to post all questions
          await postQuestions();
        }

        dispatch(updateCourseItem(updatedItemWithType));
      } else if (mode === 'create') {
        // Create new exam
        const newItem = await createNewExam({
          ...examInput,
          courseId,
          parentFolderId,
          questions: [],
        });
        const newItemWithType = {
          ...newItem.data,
          type: 'exam',
          itemId: newItem.data.examId,
        };
        // Post all questions to server
        const postQuestions = async () => {
          for (const question of examInput.questions) {
            const questionData = new FormData();
            questionData.append('section', question.section);
            questionData.append('sectionContent', question.sectionContent);
            questionData.append('index', question.index.toString());
            questionData.append('type', question.type);
            questionData.append('correctAnswer', question.correctAnswer);
            questionData.append('questionImageUrl', question.questionImageUrl);
            questionData.append('explainImageUrl', question.explainImageUrl);
            question.questionImage &&
              questionData.append('questionImage', question.questionImage);
            question.explainImage &&
              questionData.append('explainImage', question.explainImage);
            questionData.append('examId', newItem.data.examId);
            questionData.append('question', question.question);
            questionData.append('explain', question.explain);
            questionData.append('questionA', question.questionA);
            questionData.append('questionB', question.questionB);
            questionData.append('questionC', question.questionC);
            questionData.append('questionD', question.questionD);

            try {
              const newQuestion = await addQuestion(questionData);
              // Handle success, e.g., log or update state
            } catch (error) {
              // Handle error for each question
              console.error(`Failed to add question ${question.index}:`, error);
            }
          }
        };
        // Call the function to post all questions
        await postQuestions();
        dispatch(addCourseItem(newItemWithType));
      }

      //closeDialog();
    } catch (err) {
      console.error('Error saving exam:', err);
    } finally {
      setLoadingButton(false);
      closeDialog();
    }
  };

  const renderContent = () => {
    return (
      <>
        {/* <div
          style={{
            display: activeTab === 0 ? 'block' : 'none',
            height: '100%',
          }}
        >
          <ExamPage
            exam={exam || defaultExam}
            mode={mode}
            examInput={examInput}
            setExamInput={setExamInput}
            isTeacher={isTeacher}
          />
        </div> */}
        <div
          style={{
            display: activeTab === 0 ? 'block' : 'none',
            height: '100%',
          }}
        >
          <AnswerResultPage
            exam={exam || defaultExam}
            mode={mode}
            examInput={examInput}
            setExamInput={setExamInput}
            isTeacher={isTeacher}
            isNotOnDialog={isNotOnDialog}
          />
        </div>
        <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
          <Typography variant="h6">Leader Board Content Goes Here</Typography>
        </div>
      </>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        pt: mode === 'view' ? 0 : 3,
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      {/* X button */}
      {/* <Box
        sx={{
          position: 'absolute',
          top: 20,
          zIndex: 1,
          right: {
            xs: 0,
            md: 10,
          },
        }}
      >
        <Button onClick={closeDialog}>
          <CloseIcon />
        </Button>
      </Box> */}
      {/* Stepper */}
      {mode === 'view' ? (
        <>
          {/* Content */}
          <Box
            sx={{
              my: 1,
              position: 'relative',
            }}
          >
            <Box display="flex" justifyContent="center">
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                {fullSteps.map((step) => (
                  <Tab key={step.label} label={step.label} icon={step.icon} />
                ))}
              </Tabs>
            </Box>

            {renderContent()}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            my: 1,
          }}
        >
          <AnswerResultPage
            exam={exam || defaultExam}
            mode={mode}
            examInput={examInput}
            setExamInput={setExamInput}
            isTeacher={isTeacher}
            isNotOnDialog={isNotOnDialog}
          />
        </Box>
      )}

      {/* Save Button */}
      {!isNotOnDialog && (mode === 'edit' || mode === 'create') && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={loadingButton || isDataFilled}
          >
            {loadingButton ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Save'
            )}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={closeDialog}
            sx={{ ml: 1 }}
          >
            Close
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ExamTabs;
