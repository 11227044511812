import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';

type CourseInfoItemProps = {
  icon: React.ReactNode;
  value: string | number;
  label?: string;
  URL?: boolean; // New prop to determine if value should be a link
  href?: string;
  sx?: SxProps;
  ml?: number;
  variant?: 'subtitle1' | 'subtitle2';
  isWrap?: boolean;
};

const InfoItem: React.FC<CourseInfoItemProps> = ({
  icon,
  value,
  label,
  URL = false, // Default to false
  href = '',
  sx,
  ml = 1,
  variant = 'subtitle2',
  isWrap = true,
}) => {
  // Convert value to string for href attribute if URL is true

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      sx={{ maxWidth: '100%', mb: 1, ...sx }}
    >
      {icon}
      {URL ? (
        <Typography
          variant={variant}
          ml={ml}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flex: 1,
            textDecoration: 'underline', // Underline to indicate a link
            color: 'text.primary', // Change color to primary
            transition: 'all 0.3s ease', // Smooth transition effect
            '&:hover': { fontSize: '0.95rem' }, // Change color on hover
          }}
          component="a"
          href={href}
          target="_blank" // Open in a new tab
          rel="noopener noreferrer" // Security measure
          // Create hover effect that increases size slightly
        >
          {value} {label || ''}
        </Typography>
      ) : (
        <Typography
          variant={variant}
          ml={ml}
          sx={
            isWrap
              ? {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  flex: 1,
                }
              : { whiteSpace: 'normal' }
          }
        >
          {value} {label || ''}
        </Typography>
      )}
    </Box>
  );
};

export default InfoItem;
