import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  IconButton,
  Stack,
  Divider,
} from '@mui/material';
import { CreateExamPageProps, Question, SectionType } from './type';
import ExamDetails from './ExamDetails';
import { handleWord } from './docUtils';
import QuestionComponent from './QuestionComponent';
import { extractQuestions, renderHTMLContent } from './extractQuestions';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { appActions } from '../../redux/slices/appSlice';
import { useAppDispatch } from '../../hooks/redux';
import useResponsive from '../../hooks/useResponsive';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';

interface ContentItem {
  type: 'text' | 'equation';
  content: string;
  position: number;
}

//Custom professional icon button
export const OutlinedButton: React.FC<{
  icon: React.ReactNode;
  onClick: () => void;
}> = ({ icon, onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      // border: '1px solid',
      // borderColor: 'primary.main',
      borderRadius: '50%',
      padding: 1,
      margin: 1,
      color: 'primary.main',
      width: 40,
      height: 40,
      // hover effect
      '&:hover': {
        backgroundColor: 'primary.light',
      },
    }}
  >
    {icon}
  </IconButton>
);

const AnswerResultPage: React.FC<CreateExamPageProps> = ({
  exam,
  mode = 'view',
  examInput,
  setExamInput,
  isTeacher,
  isNotOnDialog,
}) => {
  const dispatch = useAppDispatch();
  const isMobile = useResponsive('down', 'md');

  //State to handle questions
  const [questions, setQuestions] = React.useState<Question[]>([]);

  const [activeQuestion, setActiveQuestion] = React.useState<Question | null>(
    null
  );

  const [viewMode, setViewMode] = React.useState<'none' | 'list' | 'single'>(
    'none'
  );

  const [htmlContent, setHtmlContent] = React.useState<string | null>(null);
  const [wordName, setWordName] = React.useState<string>('');

  const [loadingQuestions, setLoadingQuestions] = React.useState(false);
  const [viewAnswer, setViewAnswer] = React.useState(false);

  const navigate = useNavigate();

  //Create new array of sections include title and questions (questions are grouped by same section)
  const sections = questions.reduce((acc, question) => {
    const sectionIndex = acc.findIndex(
      (section) => section.title === question.section
    );
    if (sectionIndex === -1) {
      acc.push({
        title: question.section,
        content: question.sectionContent,
        questions: [question],
      });
    } else {
      acc[sectionIndex].questions.push(question);
    }
    return acc;
  }, [] as SectionType[]);

  useEffect(() => {
    if (examInput.questions.length > 0) {
      setQuestions(examInput.questions);
      setActiveQuestion(examInput.questions[0]);
      setViewMode('single');
    }
  }, [examInput.questions]);

  const showSingleQuestion = (question: Question) => {
    setActiveQuestion(question);
    setViewMode('single');
  };

  // const showListQuestions = async () => {
  //   setViewMode('list');
  // };

  const processWord = async (file: File) => {
    setWordName(file.name);

    try {
      const html = await handleWord(file); // Get HTML content

      setHtmlContent(html);
      try {
        setLoadingQuestions(true);
        const newQuestions = extractQuestions(html); // Extract questions from HTML content

        console.log('Questions:', newQuestions);

        setQuestions(newQuestions);

        setViewMode('single');
        setLoadingQuestions(false);

        setActiveQuestion(newQuestions[0]);
        setExamInput((prevState) => ({
          ...prevState,
          questions: newQuestions,
          isEdittingQuestion: true,
        }));
      } catch (error: any) {
        dispatch(
          appActions.showNotification({
            variant: 'error',
            message: error.message,
          })
        );
      }
    } catch (error) {
      console.error('Error processing file:', error);
    }
  };

  const handleUploadWord = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    processWord(file);
  };

  const formatAnswer = (questionIndex: number, question: Question) => {
    let formattedAnswer = question.correctAnswer;

    if (question.type === 'mctf') {
      // Convert '1' to 'Đ' and '0' to 'S'
      formattedAnswer = question.correctAnswer
        .split('') // Split the answer into individual characters
        .map((char) => (char === '1' ? 'Đ' : 'S')) // Map '1' to 'Đ' and '0' to 'S'
        .join(''); // Join the array back into a string
    }

    return `${questionIndex + 1}. ${formattedAnswer}`;
  };

  const desktopHeight = isNotOnDialog ? '82vh' : '70vh';
  const mobileHeight = isNotOnDialog ? '92vh' : '92vh';

  if (mode === 'view' && exam.isExercise && !viewAnswer) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        sx={{
          minHeight: desktopHeight,
        }}
      >
        <Stack direction="column" spacing={2} mt={6}>
          <ExamDetails
            exam={exam}
            mode={mode}
            examInput={examInput}
            setExamInput={setExamInput}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              // Navigate to the examming page which like /examming/:examId

              navigate(`/examming/${exam.examId}`);
            }}
            startIcon={<KeyboardArrowRightIcon />}
          >
            Làm bài
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              setViewAnswer(true);
            }}
            startIcon={<TextSnippetIcon />}
          >
            Xem đáp án
          </Button>
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        minHeight: desktopHeight,
        flexDirection: {
          xs: 'column-reverse',
          md: 'row',
        },
      }}
    >
      {/* Left Panel */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexGrow: 1,
          padding: 1,
          px: 0,
          height: isMobile ? mobileHeight : desktopHeight,

          '&::-webkit-scrollbar': {
            height: '8px', // Set the height of the horizontal scrollbar
          },
        }}
      >
        {viewMode === 'none' && mode !== 'view' && (
          <Stack direction="column" width="100%">
            <Typography
              variant="examText"
              align="center"
              color="textSecondsary"
              fontWeight="bold"
            >
              Chọn file word có mẫu như sau
            </Typography>

            <iframe
              src={
                'https://res.cloudinary.com/dmpprfvzq/raw/upload/v1727409084/pdf-pdfs/hmmlb52kt9tnhzafgsaj.pdf' +
                '#toolbar=0'
              }
              style={{
                width: '100%',
                height: isMobile ? '75vh' : '100%',
                border: 'none',
              }}
              title={examInput.title}
            />
          </Stack>
        )}

        {viewMode === 'single' && (
          <Box width="100%" maxWidth="100%" position="relative" height="100%">
            <Box
              mb={1}
              sx={{
                px: 0,
                height: 'calc(100% - 60px)',
              }}
            >
              {activeQuestion && (
                <QuestionComponent
                  question={activeQuestion}
                  editable={false}
                  sx={{
                    maxHeight: '100%',
                    overflow: 'auto',
                  }}
                />
              )}
            </Box>
            {/* Bottom Navigation to navigate between questions
            The design would be "Previous" and "Next" buttons at left and right
            Rounded question number buttons in the middle (show atmost 5 at the same time) */}
            <Box
              sx={{
                position: 'absolute',
                height: 40,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 1,
              }}
            >
              <OutlinedButton
                icon={<KeyboardArrowLeftIcon />}
                onClick={() => {
                  if (activeQuestion) {
                    const index = questions.indexOf(activeQuestion);
                    if (index > 0) {
                      setActiveQuestion(questions[index - 1]);
                    }
                  }
                }}
              />
              <Typography variant="subtitle2">
                {/* {activeQuestion?.section} - Câu {} {activeQuestion?.index} -{' '}
                {''} */}
                {activeQuestion ? questions.indexOf(activeQuestion) + 1 : 0} /{' '}
                {questions.length}
              </Typography>

              <OutlinedButton
                icon={<KeyboardArrowRightIcon />}
                onClick={() => {
                  if (activeQuestion) {
                    const index = questions.indexOf(activeQuestion);
                    if (index < questions.length - 1) {
                      setActiveQuestion(questions[index + 1]);
                    }
                  }
                }}
              />
            </Box>
          </Box>
        )}
      </Box>

      {/* Right Panel */}
      <Box
        sx={{
          minWidth: 230,
          width: {
            xs: '100%',
            md: '20%',
          },
          padding: 1,
          maxHeight: isMobile ? 'none' : desktopHeight,
          overflow: isMobile ? 'hidden' : 'auto',
        }}
      >
        {isTeacher && (mode === 'edit' || mode === 'create') ? (
          //If activeFile is null, show upload button, else show reload button
          <Button
            variant="contained"
            component="label"
            sx={{ mb: 2, width: '100%' }}
          >
            <TextSnippetIcon sx={{ mr: 1 }} />
            Tải file Word
            <input
              type="file"
              name="answerURL"
              accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={(e) => {
                handleUploadWord(e); // Call your existing handler
                e.target.value = ''; // Reset the input value to allow the same file to be chosen again
              }}
              hidden
            />
          </Button>
        ) : (
          // <Typography variant="subtitle2" sx={{ mb: 2 }}>
          //   Danh sách câu hỏi
          // </Typography>
          <></>
        )}

        {viewMode !== 'none' && (
          <Box sx={{}}>
            {/* Section for questions */}
            <Box sx={{ my: 1, display: isMobile ? 'none' : 'block' }}>
              {sections.map((section, sectionIndex) => (
                <Box
                  sx={{ my: 1 }}
                  key={`section-${sectionIndex}`} // Unique key for section
                >
                  <Typography variant="subtitle2" gutterBottom>
                    {section.title}
                  </Typography>
                  <Grid container spacing={0.5}>
                    {section.questions.map((question, questionIndex) => (
                      <Grid
                        item
                        xs={question.type === 'mcq' ? 4 : 6}
                        key={question.questionId}
                      >
                        <Button
                          variant={
                            viewMode === 'single' && activeQuestion === question
                              ? 'contained'
                              : 'outlined'
                          }
                          sx={{ width: '100%' }}
                          key={`question-${sectionIndex}-${questionIndex}`} // Unique key for each question
                          onClick={() => {
                            showSingleQuestion(question);
                          }}
                        >
                          {formatAnswer(questionIndex, question)}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}
              <Divider
                sx={{
                  mb: 1,
                  backgroundColor: 'text.secondary',
                  opacity: 0.3,
                }}
              />
            </Box>
            <Box px={isMobile ? 0 : 1}>
              <ExamDetails
                exam={exam}
                mode={mode}
                examInput={examInput}
                setExamInput={setExamInput}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AnswerResultPage;
