import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, TextField, Divider } from '@mui/material';
import { Question } from './type';
import { renderHTMLContent } from './extractQuestions';

// Props for the SAQuestion component
interface SAQuestionProps {
  question: Question;
  editable?: boolean;
  onAnswerChange?: (answer: string) => void;
  answer?: string;
  formatSize?: number;
}

const SAQuestion: React.FC<SAQuestionProps> = ({
  question,
  editable,
  onAnswerChange,
  answer,
  formatSize = 1,
}) => {
  const [answers, setAnswers] = useState({
    A: '',
    B: '',
    C: '',
    D: '',
  });

  // Create refs for the input fields
  const inputRefs = {
    A: useRef<HTMLInputElement>(null),
    B: useRef<HTMLInputElement>(null),
    C: useRef<HTMLInputElement>(null),
    D: useRef<HTMLInputElement>(null),
  };

  // Set the correct answers for the question
  useEffect(() => {
    let str = editable ? answer : question.correctAnswer;
    if (!str) {
      str = '';
    }
    // Split the answer string
    const correctAnswers = str.split('');
    if (correctAnswers[0] === ' ') {
      correctAnswers.shift();
    }
    // If the correct answer length is less than 4, pad it with empty strings to make it 4
    while (correctAnswers.length < 4) {
      correctAnswers.push('');
    }
    setAnswers({
      A: correctAnswers[0],
      B: correctAnswers[1],
      C: correctAnswers[2],
      D: correctAnswers[3],
    });
  }, [question.correctAnswer, editable, answer]);

  const handleInputChange = (part: 'A' | 'B' | 'C' | 'D', value: string) => {
    // Only accept '-', ',' or numbers
    const regex = /^[-,0-9]*$/;
    if (regex.test(value)) {
      const newAnswers = { ...answers, [part]: value };
      setAnswers(newAnswers);

      onAnswerChange &&
        onAnswerChange(
          `${newAnswers.A || ''}${newAnswers.B || ''}${newAnswers.C || ''}${
            newAnswers.D || ''
          }`
        ); // Convert the answers to a string

      // Automatically move to the next input field if the value is not empty
      if (value && part !== 'D') {
        const nextPart = String.fromCharCode(part.charCodeAt(0) + 1) as
          | 'A'
          | 'B'
          | 'C'
          | 'D';
        inputRefs[nextPart]?.current?.focus();
      }
    }
  };

  const handleKeyDown = (
    part: 'A' | 'B' | 'C' | 'D',
    event: React.KeyboardEvent
  ) => {
    if (event.key === 'Backspace' && !answers[part]) {
      const prevPart = String.fromCharCode(part.charCodeAt(0) - 1) as
        | 'A'
        | 'B'
        | 'C'
        | 'D';

      inputRefs[prevPart]?.current?.focus();
    }
  };

  // // Function to check if the user's answers are correct
  // const isAnswerCorrect = (part: 'A' | 'B' | 'C' | 'D') => {
  //   const answerValue = answers[part];
  //   const index = part.charCodeAt(0) - 'A'.charCodeAt(0); // Get the index for A, B, C, D
  //   return answerValue === correctAnswers[index];
  // };

  return (
    <Box>
      {/* Render the four square text fields for the PIN-like input */}
      <Box display="flex" mt={1} mb={1.5}>
        {['A', 'B', 'C', 'D'].map((part) => (
          <TextField
            autoComplete="off"
            key={part}
            value={answers[part as 'A' | 'B' | 'C' | 'D']}
            inputRef={inputRefs[part as 'A' | 'B' | 'C' | 'D']}
            onChange={(e) =>
              handleInputChange(part as 'A' | 'B' | 'C' | 'D', e.target.value)
            }
            onKeyDown={(e) => handleKeyDown(part as 'A' | 'B' | 'C' | 'D', e)}
            variant="standard"
            inputProps={{
              maxLength: 1, // Only one character per box
              style: {
                textAlign: 'center',
                fontWeight: 600, // Make the text bold
                fontSize: formatSize + 0.2 + 'rem', // Set the font size
                color: 'prize.first', // Set the text color
              }, // Center the text inside the box
            }}
            sx={{
              width: formatSize + 2.5 + 'rem', // Set the width of the box
              mr: 0.3, // Add margin between the boxes
              '& .MuiInput-root': {
                color: 'prize.first', // Set the text color
                borderRadius: '12px', // Make it a square shape with rounded corners
                '&:before': {
                  borderColor: 'prize.first',
                },
                '&:after': {
                  borderColor: 'prize.first',
                },
                '&:hover:not(.Mui-disabled):before': {
                  borderColor: 'prize.first',
                },
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SAQuestion;
