import { SERVER } from './constants/url';
import axios from 'axios';
import {
  AnswerType,
  FetchAnswerIdType,
  FetchAnswerType,
  LBReqType,
  UpdateHardLevelType,
} from './model/Exam';
import { LoginData, SignUpData } from './model/Student';
import { Exam } from './pages/Exams/type';
import { VideoPdfItem } from './pages/VideoPdf/type';

export const getUpcomingLesson = async (type: string) =>
  axios.get(`${SERVER}/api/lessons/upcoming/${type}`);

export const getClosestUpcomingLesson = async () =>
  axios.get(`${SERVER}/api/lessons/upcoming`);

export const getPrevLesson = async (type: string) =>
  axios.get(`${SERVER}/api/lessons/prev/${type}`);

export const getAllLesson = async () => axios.get(`${SERVER}/api/lessons`);

export const signup = async (data: SignUpData) =>
  axios.post(`${SERVER}/api/auth/signup`, data, { withCredentials: true });

export const login = async (data: LoginData) =>
  axios.post(`${SERVER}/api/auth/login`, data, { withCredentials: true });

export const logout = async () =>
  axios.get(`${SERVER}/api/auth/logout`, { withCredentials: true });

export const getMe = async () =>
  axios.get(`${SERVER}/api/students/me`, { withCredentials: true });

export const getStudent = async (id: string, token: string) =>
  axios.post(`${SERVER}/api/students/${id}`, { token: token });

export const updateProfile = async (data: FormData) =>
  axios.patch(`${SERVER}/api/students/updateMe`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    withCredentials: true,
  });

export const changePassword = async (data: { password: string }) =>
  axios.patch(`${SERVER}/api/students/updateMyPassword`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

// Function to make a student a teacher
export const makeTeacher = async (email: string) => {
  return axios.post(`${SERVER}/api/students/makeTeacher`, { email });
};

// Function to find students by email
export const findEmails = async (email: string) => {
  return axios.get(`${SERVER}/api/students/findEmails`, {
    params: { email }, // Pass the email as a query parameter
  });
};

// Exams

// export const getExams = async () => axios.get(`${SERVER}/api/exams`);
// export const getExamById = async (id: string) =>
//   axios.get(`${SERVER}/api/exams/${id}`);

// export const getExamByName = async (name: string) =>
//   axios.get(`${SERVER}/api/exams/name/${name}`);

// export const getExamsByType = async (type: string) =>
//   axios.get(`${SERVER}/api/exams/type/${type}`);

// export const fetchChart = async (id: string) =>
//   axios.get(`${SERVER}/api/exams/chart/${id}`);

// export const registerExam = async (examId: string, studentId: string) => {
//   const requestData = { examId, studentId };

//   const response = await axios.post(
//     `${SERVER}/api/exams/registerExam`,
//     requestData,
//     {
//       withCredentials: true,
//     }
//   );
//   return response.data;
// };

//Filters
export const getFilters = async () => axios.get(`${SERVER}/api/filters`);

//Answers

export const postAnswer = async (data: AnswerType) => {
  const requestData = data;
  const response = await axios.post(`${SERVER}/api/answer`, requestData);
  return response.data;
};

export const fetchAnswer = async (data: FetchAnswerType) => {
  const requestData = data;
  const response = await axios.post(`${SERVER}/api/answer/fetch`, requestData);
  return response.data;
}; //Dùng khi xem đáp án, lấy kết quả cao nhất

export const fetchAnswerById = async (data: FetchAnswerIdType) => {
  const requestData = data;
  const response = await axios.post(
    `${SERVER}/api/answer/fetchId`,
    requestData
  );
  return response.data;
}; //Dùng khi mới kiểm tra xong, cần lấy để xem phát thưởng chưa

export const updateHardLevel = async (data: UpdateHardLevelType) => {
  const requestData = data;
  const response = await axios.post(
    `${SERVER}/api/answer/updateHardLevel`,
    requestData
  );
  return response.data;
}; //Dùng khi sau kiểm tra, update đánh giá

//Leader Board

export const getStudentLBs = async (data: LBReqType) => {
  const requestData = data;
  const response = await axios.post(`${SERVER}/api/leaderBoard`, requestData);
  return response.data; // You can process the response as needed
};

//Feedback

export const postFb = async (data: {
  feedBack: string;
  student: string;
  exam: string;
}) => {
  const requestData = data;
  const response = await axios.post(`${SERVER}/api/feedBack`, requestData);
  return response.data; // You can process the response as needed
};

//Time

export const getTime = async () => axios.get(`${SERVER}/api/time`);

//Courses

// Fetch all courses
export const fetchAllCourses = async () => {
  try {
    const response = await axios.get(`${SERVER}/api/course`);
    return response.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error; // Re-throw the error to handle it in the calling code
  }
};

// Get a course by ID
export const getCourse = async (courseId: string) => {
  try {
    const response = await axios.get(`${SERVER}/api/course/${courseId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting course:', error);
    throw error;
  }
};

// Add a new course
export const addCourse = async (data: FormData) => {
  try {
    const response = await axios.post(`${SERVER}/api/course`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding course:', error);
    throw error;
  }
};

// Update an existing course by ID
export const updateCourseById = async (courseId: string, updatedData: any) => {
  try {
    const response = await axios.put(
      `${SERVER}/api/course/${courseId}`,
      updatedData
    );
    return response.data;
  } catch (error) {
    console.error('Error updating course:', error);
    throw error;
  }
};

// Delete a course by ID
export const deleteCourseById = async (courseId: string) => {
  try {
    await axios.delete(`${SERVER}/api/course/${courseId}`);
  } catch (error) {
    console.error('Error deleting course:', error);
    throw error;
  }
};

export const getCourseById = async (id: string) => {
  try {
    const response = await axios.get(`${SERVER}/api/course/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error getting course:', error);
    throw error;
  }
};

export const addLinkToCourse = async (courseId: string, link: string) => {
  try {
    const response = await axios.put(
      `${SERVER}/api/course/addlink/${courseId}`,
      {
        link,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error adding link to course:', error);
    throw error;
  }
};

export const addContactLinkToCourse = async (
  courseId: string,
  contactLink: string
) => {
  try {
    const response = await axios.put(
      `${SERVER}/api/course/addcontactlink/${courseId}`,
      {
        contactLink,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error adding contact link to course:', error);
    throw error;
  }
};

export const deleteLinkFromCourse = async (courseId: string, link: string) => {
  try {
    const response = await axios.put(
      `${SERVER}/api/course/deletelink/${courseId}`,
      {
        link,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting link from course:', error);
    throw error;
  }
};

export const addLearningObjectiveToCourse = async (
  courseId: string,
  objective: string
) => {
  try {
    const response = await axios.put(
      `${SERVER}/api/course/addlearningobjective/${courseId}`,
      {
        objective,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error adding learning objective to course:', error);
    throw error;
  }
};

export const deleteLearningObjectiveFromCourse = async (
  courseId: string,
  objective: string
) => {
  try {
    const response = await axios.put(
      `${SERVER}/api/course/deletelearningobjective/${courseId}`,
      {
        objective,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting learning objective from course:', error);
    throw error;
  }
};

//Exam
export const postExam = async (data: Exam) => {
  const requestData = data;
  try {
    const response = await axios.post(`${SERVER}/api/exams`, requestData);
    return response.data;
  } catch (error) {
    console.error('Error posting exam:', error);
    throw error;
  }
};

export const createNewExam = async (exam: any) => {
  try {
    console.log('exam', exam);

    const response = await postExam(exam);
    return response;
  } catch (error) {
    console.error('Error creating new exam:', error);
    throw error;
  }
};

export const fetchExamById = async (id: string) => {
  try {
    const response = await axios.get(`${SERVER}/api/exams/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching exam:', error);
    throw error;
  }
};

export const fetchExamWithoutAnswerById = async (id: string) => {
  try {
    const response = await axios.get(`${SERVER}/api/exams/withoutAnswer/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching exam without answer:', error);
    throw error;
  }
};

export const deleteExamById = async (id: string) => {
  try {
    await axios.delete(`${SERVER}/api/exams/${id}`);
  } catch (error) {
    console.error('Error deleting exam:', error);
    throw error;
  }
};

export const updateExamById = async (id: string, updatedData: any) => {
  try {
    const response = await axios.put(`${SERVER}/api/exams/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating exam:', error);
    throw error;
  }
};

//router.delete('/deleteAll/:examId', deleteQuestionsByExamId);
export const deleteQuestionsByExamId = async (examId: string) => {
  try {
    await axios.delete(`${SERVER}/api/exams/deleteAll/${examId}`);
  } catch (error) {
    console.error('Error deleting questions by exam ID:', error);
    throw error;
  }
};

// PDF API
export const postPDF = async (data: VideoPdfItem) => {
  try {
    const response = await axios.post(`${SERVER}/api/pdfs`, data);
    return response.data;
  } catch (error) {
    console.error('Error posting PDF:', error);
    throw error;
  }
};

export const createNewPDF = async (pdf: any) => {
  try {
    const response = await postPDF(pdf);
    return response;
  } catch (error) {
    console.error('Error creating new PDF:', error);
    throw error;
  }
};

export const fetchPDFById = async (id: string) => {
  try {
    const response = await axios.get(`${SERVER}/api/pdfs/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching PDF:', error);
    throw error;
  }
};

export const deletePDFById = async (id: string) => {
  try {
    await axios.delete(`${SERVER}/api/pdfs/${id}`);
  } catch (error) {
    console.error('Error deleting PDF:', error);
    throw error;
  }
};

export const updatePDFById = async (id: string, updatedData: any) => {
  try {
    const response = await axios.put(`${SERVER}/api/pdfs/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating PDF:', error);
    throw error;
  }
};

// Video API
export const postVideo = async (data: VideoPdfItem) => {
  try {
    const response = await axios.post(`${SERVER}/api/videos`, data);
    return response.data;
  } catch (error) {
    console.error('Error posting video:', error);
    throw error;
  }
};

export const createNewVideo = async (video: any) => {
  try {
    const response = await postVideo(video);
    return response;
  } catch (error) {
    console.error('Error creating new video:', error);
    throw error;
  }
};

export const fetchVideoById = async (id: string) => {
  try {
    const response = await axios.get(`${SERVER}/api/videos/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching video:', error);
    throw error;
  }
};

export const deleteVideoById = async (id: string) => {
  try {
    await axios.delete(`${SERVER}/api/videos/${id}`);
  } catch (error) {
    console.error('Error deleting video:', error);
    throw error;
  }
};

export const updateVideoById = async (id: string, updatedData: any) => {
  try {
    const response = await axios.put(`${SERVER}/api/videos/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating video:', error);
    throw error;
  }
};

// SubFolder API
export const createNewSubFolder = async (
  courseId: string,
  parentFolderId: string,
  title: string
) => {
  const newItem = {
    courseId, // Set the courseId dynamically
    parentFolderId, // Set the parentFolderId dynamically if needed
    title,
  };
  try {
    const response = await axios.post(`${SERVER}/api/subfolders`, newItem);
    return response.data;
  } catch (error) {
    console.error('Error creating new SubFolder:', error);
    throw error;
  }
};

export const fetchSubFolderById = async (id: string) => {
  try {
    const response = await axios.get(`${SERVER}/api/subfolders/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching SubFolder:', error);
    throw error;
  }
};

export const deleteSubFolderById = async (id: string) => {
  try {
    await axios.delete(`${SERVER}/api/subfolders/${id}`);
  } catch (error) {
    console.error('Error deleting SubFolder:', error);
    throw error;
  }
};

export const updateSubFolderById = async (id: string, updatedData: any) => {
  try {
    const response = await axios.put(
      `${SERVER}/api/subfolders/${id}`,
      updatedData
    );
    return response.data;
  } catch (error) {
    console.error('Error updating SubFolder:', error);
    throw error;
  }
};

// Favorite and BuyCourse API

export const addFavorite = async (studentId: string, courseId: string) => {
  try {
    const response = await axios.post(`${SERVER}/api/favorite/add`, {
      studentId,
      courseId,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding favorite:', error);
    throw error;
  }
};

export const removeFavorite = async (studentId: string, courseId: string) => {
  try {
    const response = await axios.delete(`${SERVER}/api/favorite/remove`, {
      data: {
        // Axios uses `data` for DELETE requests with a body
        studentId,
        courseId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error removing favorite:', error);
    throw error;
  }
};

export const fetchFavorites = async (studentId: string, courseId: string) => {
  try {
    const response = await axios.post(`${SERVER}/api/favorite/isfavorite`, {
      studentId,
      courseId,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching favorites:', error);
    throw error;
  }
};

export const addBuyCourse = async (studentId: string, courseId: string) => {
  try {
    const response = await axios.post(`${SERVER}/api/buyCourse/add`, {
      studentId,
      courseId,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding buy course:', error);
    throw error;
  }
};

export const removeBuyCourse = async (studentId: string, courseId: string) => {
  try {
    const response = await axios.delete(`${SERVER}/api/buyCourse/remove`, {
      data: {
        // Axios uses `data` for DELETE requests with a body
        studentId,
        courseId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error removing buy course:', error);
    throw error;
  }
};

export const fetchBuyCourses = async (studentId: string, courseId: string) => {
  try {
    const response = await axios.post(`${SERVER}/api/buyCourse/isbuy`, {
      studentId,
      courseId,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching buy courses:', error);
    throw error;
  }
};

export const fetchBuyCoursesByStudentId = async (studentId: string) => {
  try {
    const response = await axios.get(
      `${SERVER}/api/buyCourse/getbuy/${studentId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching buy courses by student ID:', error);
    throw error;
  }
};

export const addRate = async (
  studentId: string,
  courseId: string,
  rate: number
) => {
  try {
    const response = await axios.post(`${SERVER}/api/rate/add`, {
      studentId,
      courseId,
      rate: rate,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding buy course:', error);
    throw error;
  }
};

export const removRate = async (studentId: string, courseId: string) => {
  try {
    const response = await axios.delete(`${SERVER}/api/rate/remove`, {
      data: {
        // Axios uses `data` for DELETE requests with a body
        studentId,
        courseId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error removing buy course:', error);
    throw error;
  }
};

export const fetchRate = async (studentId: string, courseId: string) => {
  try {
    const response = await axios.post(`${SERVER}/api/rate/israte`, {
      studentId,
      courseId,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching buy courses:', error);
    throw error;
  }
};

//Question
// Add a new question
export const addQuestion = async (data: FormData) => {
  try {
    const response = await axios.post(`${SERVER}/api/question`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding question:', error);
    throw error;
  }
};

// Update an existing question by ID
export const updateQuestionById = async (
  questionId: string,
  updatedData: any
) => {
  try {
    const response = await axios.put(
      `${SERVER}/api/question/${questionId}`,
      updatedData
    );
    return response.data;
  } catch (error) {
    console.error('Error updating question:', error);
    throw error;
  }
};

// Docx to HTML
export const docxToHtml = async (data: any) => {
  try {
    const response = await axios.post(`${SERVER}/api/pandoc`, data);
    return response.data;
  } catch (error) {
    console.error('Error creating new PDF:', error);
    throw error;
  }
};
