import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

interface CourseImageProps {
  image: string;
}

const CourseImage: React.FC<CourseImageProps> = ({ image }) => (
  <img
    src={image}
    alt="Course"
    style={{
      width: '100%',
      marginBottom: '16px',
      maxHeight: '250px',
      objectFit: 'cover',
      borderRadius: 16,
    }}
  />
);

export default CourseImage;
