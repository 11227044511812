import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  IconButton,
  Stack,
  Divider,
  Drawer,
  Toolbar,
  useTheme,
  AppBar,
  Container,
  Slider,
  alpha,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
} from '@mui/material';
import {
  CreateExamPageProps,
  defaultExam,
  defaultResult,
  Exam,
  ExammingPageProps,
  Question,
  Result,
  SectionType,
} from './type';
import ExamDetails from './ExamDetails';
import { handleWord } from './docUtils';
import QuestionComponent from './QuestionComponent';
import { extractQuestions, renderHTMLContent } from './extractQuestions';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { appActions } from '../../redux/slices/appSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useResponsive from '../../hooks/useResponsive';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchExamById, fetchExamWithoutAnswerById } from '../../api';
import { OutlinedButton } from './AnswerResultPage';
import InfoItem from '../Courses/CourseInfoItem';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import useAuth from '../../hooks/useAuth';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import MainButton from '../Courses/MainButton';
import AppDialogs from '../../components/AppDialogs';
import LoginDialog from './LoginDialog';

const drawerWidth = 240;

const ExammingPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMobile = useResponsive('down', 'md');
  const theme = useTheme();
  const { user } = useAuth();
  const { examId } = useParams<{ examId: string }>();
  const [exam, setExam] = React.useState<Exam>(defaultExam);
  const [result, setResult] = React.useState<Result>(defaultResult);
  const [formatSize, setFormatSize] = React.useState<number>(1);
  const [openNav, setOpenNav] = React.useState<boolean>(() =>
    isMobile ? false : true
  );
  const isDarkMode = useAppSelector((state) => state.app.isDarkMode);
  const showUpdateProfile = useAppSelector(
    (state) => state.app.showUpdateProfileModal
  );
  const [transition, setTransition] = React.useState(false);
  const [timeLeft, setTimeLeft] = useState(exam.duration * 60);

  const handleToggleNavMenu = () => {
    setOpenNav(!openNav);
  };

  const [questions, setQuestions] = React.useState<Question[]>([]);

  const [activeQuestion, setActiveQuestion] = React.useState<Question | null>(
    null
  );

  const [start, setStart] = React.useState(false);

  const [isFullscreen, setIsFullscreen] = useState(false);

  const [showUserForm, setShowUserForm] = useState(true);

  useEffect(() => {
    if (!showUserForm) dispatch(appActions.showUpdateProfileWithLogout());
  }, [dispatch, showUserForm]);

  useEffect(() => {
    console.log(
      'showUpdateProfile:',
      showUpdateProfile,
      'user:',
      user,
      'exam:',
      exam,
      'showUserForm:',
      showUserForm
    );
    if (
      !showUserForm &&
      user &&
      !showUpdateProfile &&
      exam &&
      exam.isExercise
    ) {
      setStart(true);
    }
  }, [showUserForm, user, showUpdateProfile, exam]);

  useEffect(() => {
    if (!start) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft > 0) {
          return prevTimeLeft - 1;
        } else {
          clearInterval(timer);
          // Handle exam end logic here, such as submitting the answers
          return 0;
        }
      });
    }, 1000);

    // Cleanup function to clear the timer when the component unmounts or the timer finishes
    return () => clearInterval(timer);
  }, [start]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  useEffect(() => {
    if (timeLeft === 0) {
      // Logic to submit the exam or notify the user that time is up
      console.log('Time is up!');
    }
  }, [timeLeft]);

  useEffect(() => {
    if (!user) {
      setShowUserForm(true);
    } else {
      setShowUserForm(false);
    }
  }, [user]);

  //For later use
  // useEffect(() => {
  //   const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //     event.preventDefault();
  //     event.returnValue = ''; // This shows a confirmation dialog
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // Function to toggle fullscreen
  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  // Update fullscreen state when fullscreen changes
  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  // Set up event listener for fullscreen changes
  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  // Fetch exam data based on the examId
  useEffect(() => {
    const loadExam = async () => {
      try {
        const id = examId;
        if (!id) return;

        const res = await fetchExamWithoutAnswerById(id);
        const data = res.data;
        console.log('Exam data:', data);
        setExam(data);
      } catch (err) {
        console.error('Error fetching exam:', err);
      }
    };

    loadExam();
  }, [examId]);

  useEffect(() => {
    // Assign properties to the result object
    if (user && user._id) {
      setResult({
        ...result,
        studentId: user._id,
        examId: exam.examId,
        name: user.name,
        school: user.school,
      });
    }

    if (exam.questions.length > 0) {
      setResult({
        ...result,
        answers: exam.questions.map((question) => ({
          questionId: question.questionId,
          answer: '',
        })),
      });
    }
  }, [exam, user]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight' || event.key === 'Tab') {
        event.preventDefault();
        // Move to the next question
        if (activeQuestion) {
          const index = questions.indexOf(activeQuestion);
          if (index < questions.length - 1) {
            setActiveQuestion(questions[index + 1]);
          }
        }
      } else if (event.key === 'ArrowLeft') {
        event.preventDefault();

        // Move to the previous question
        if (activeQuestion) {
          const index = questions.indexOf(activeQuestion);
          if (index > 0) {
            setActiveQuestion(questions[index - 1]);
          }
        }
      }
    };

    // Attach the keydown event listener when the component is mounted
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeQuestion, questions]);

  //Create new array of sections include title and questions (questions are grouped by same section)
  const sections = questions.reduce((acc, question) => {
    const sectionIndex = acc.findIndex(
      (section) => section.title === question.section
    );
    if (sectionIndex === -1) {
      acc.push({
        title: question.section,
        content: question.sectionContent,
        questions: [question],
      });
    } else {
      acc[sectionIndex].questions.push(question);
    }
    return acc;
  }, [] as SectionType[]);

  useEffect(() => {
    if (exam.questions.length > 0) {
      setQuestions(exam.questions);
      setActiveQuestion(exam.questions[0]);
    }
  }, [exam.questions]);

  const showSingleQuestion = (question: Question) => {
    setActiveQuestion(question);
  };

  const formatAnswer = (questionIndex: number, question: Question) => {
    let formattedAnswer = question.correctAnswer;

    //find in the result array the answer of the question

    formattedAnswer = result.answers.find(
      (answer) => answer.questionId === question.questionId
    )?.answer as string;

    if (question.type === 'mctf') {
      // Convert '1' to 'Đ' and '0' to 'S'
      formattedAnswer = formattedAnswer
        .split('') // Split the answer into individual characters
        .map((char) => (char === '1' ? 'Đ' : char === '0' ? 'S' : '_')) // Map '1' to 'Đ', '0' to 'S', '_' to '_'
        .join(''); // Join the array back into a string
    }

    return `${questionIndex + 1}. ${formattedAnswer}`;
  };

  const desktopHeight = '38rem';
  const mobileHeight = '93vh';

  const AppBarItems = (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="h6"
        px={2}
        maxWidth={'30%'}
        sx={{
          display: '-webkit-box', // Required for limiting lines of text
          WebkitLineClamp: 1, // Limits to 2 lines; you can adjust this number
          WebkitBoxOrient: 'vertical', // Required for multiline ellipsis
          overflow: 'hidden', // Hides the overflowing text
          textOverflow: 'ellipsis', // Adds '...' at the end of the truncated text
        }}
      >
        {exam.title}
      </Typography>

      <Box
        sx={{
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 2,
          py: 1,
          width: isMobile ? 'auto' : drawerWidth,
        }}
      >
        <MainButton
          variant="first"
          text={formatTime(timeLeft)}
          icon={<AccessAlarmIcon fontSize="small" />}
          isLoading={false}
          onClick={() => {}} // Start the countdown on button click
          sx={{ width: 'auto', borderRadius: 2, px: 4, mr: 3 }}
        />

        <Box
          sx={{
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <PeopleOutlineIcon fontSize="small" />
          <Typography variant="subtitle2" ml={0.5}>
            {exam.examTimes}
          </Typography>
        </Box>
      </Box>
      {isMobile && (
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleToggleNavMenu}
          sx={{
            color: (theme) => theme.palette.lighter.main,
            opacity: 0.6,
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
    </Box>
  );

  const renderQuestionList = () => {
    return (
      <Box
        sx={{
          backgroundColor: 'background.default',
          px: isMobile ? 3 : 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pb: 2,
        }}
      >
        <Box sx={{ my: 1 }}>
          {sections.map((section, sectionIndex) => (
            <Box
              sx={{ my: 1 }}
              key={`section-${sectionIndex}`} // Unique key for section
            >
              <Typography variant="subtitle2" gutterBottom>
                {section.title}
              </Typography>
              <Grid container spacing={isMobile ? 1 : 0.5}>
                {section.questions.map((question, questionIndex) => (
                  <Grid
                    item
                    xs={question.type === 'mcq' ? 4 : 6}
                    key={question.questionId}
                  >
                    {activeQuestion === question ? (
                      <MainButton
                        variant="first"
                        sx={{ width: '100%', borderRadius: 4 }}
                        key={`question-${sectionIndex}-${questionIndex}`}
                        onClick={() => {
                          showSingleQuestion(question);
                        }}
                        text={formatAnswer(questionIndex, question)}
                        isLoading={false}
                      />
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{ width: '100%', borderRadius: 4 }}
                        key={`question-${sectionIndex}-${questionIndex}`} // Unique key for each question
                        onClick={() => {
                          showSingleQuestion(question);
                        }}
                      >
                        {formatAnswer(questionIndex, question)}
                      </Button>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Box>
        <MainButton
          variant="first"
          text="Nộp bài"
          icon={<TextSnippetIcon />}
          isLoading={false}
          onClick={() => {
            console.log(result);
          }}
          sx={{ width: '100%', borderRadius: 4 }}
        />
      </Box>
    );
  };

  return (
    <Container maxWidth={'xl'} disableGutters>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          color: (theme) => theme.palette.lighter.main,
          top: 0,
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            paddingX: {
              xs: 1,
            },
          }}
          disableGutters
        >
          <Toolbar
            disableGutters
            sx={{
              minHeight: '50px !important',
            }}
          >
            {AppBarItems}
          </Toolbar>
        </Container>
      </AppBar>

      {/* Left Panel */}
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          minHeight: desktopHeight,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            padding: 1,
            px: 0,
            height: isMobile ? mobileHeight : desktopHeight,
            width: '100%',
            // marginRight: openNav && !isMobile ? `${drawerWidth}px` : '0px', // Change margin based on openNav state
            transition: 'margin-right 0.3s ease-in-out',
            '&::-webkit-scrollbar': {
              height: '8px', // Set the height of the horizontal scrollbar
            },
          }}
        >
          <Box width="100%" maxWidth="100%" position="relative" height="100%">
            <Box
              mb={1}
              sx={{
                px: 2,
                height: 'calc(100% - 60px)',
                transform: transition ? 'translateX(100%)' : 'translateX(0)',
                maxWidth: '100%',
              }}
            >
              {activeQuestion && (
                <QuestionComponent
                  question={activeQuestion}
                  answer={
                    result.answers.find(
                      (answer) =>
                        answer.questionId === activeQuestion.questionId
                    )?.answer
                  }
                  editable={true}
                  sx={{
                    maxHeight: '100%',
                    overflow: 'auto',
                  }}
                  onAnswerChange={(answer) => {
                    const index = questions.indexOf(activeQuestion);
                    const newAnswers = [...result.answers];
                    newAnswers[index].answer = answer;
                    setResult({ ...result, answers: newAnswers });
                  }}
                  formatSize={formatSize}
                />
              )}
            </Box>

            <Box
              sx={{
                position: 'absolute',
                height: 40,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 1,
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                flexGrow={1}
              >
                <OutlinedButton
                  icon={<KeyboardArrowLeftIcon />}
                  onClick={() => {
                    if (activeQuestion) {
                      const index = questions.indexOf(activeQuestion);
                      if (index > 0) {
                        setActiveQuestion(questions[index - 1]);
                      }
                    }
                  }}
                />
                <Typography variant="subtitle2">
                  {activeQuestion ? questions.indexOf(activeQuestion) + 1 : 0} /{' '}
                  {questions.length}
                </Typography>

                <OutlinedButton
                  icon={<KeyboardArrowRightIcon />}
                  onClick={() => {
                    if (activeQuestion) {
                      const index = questions.indexOf(activeQuestion);
                      if (index < questions.length - 1) {
                        setActiveQuestion(questions[index + 1]);
                      }
                    }
                  }}
                />
              </Box>
              {/* Iconbutton for toggle darkmode lightmode */}
              <IconButton
                onClick={() => {
                  dispatch(appActions.toggleDarkMode());
                }}
                sx={{ color: 'prize.first' }}
              >
                {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
              </IconButton>
              {/* Slider for format size */}
              <Stack
                sx={{ width: 100, mx: 1 }}
                spacing={1}
                direction="row"
                alignItems="center"
              >
                <FormatSizeIcon fontSize="small" />
                <Slider
                  defaultValue={1}
                  step={0.1}
                  min={0.8}
                  max={1.4}
                  size="small"
                  onChange={(event, value) => {
                    if (typeof value === 'number') {
                      setFormatSize(value); // Assuming you have a state to manage format size
                    }
                  }}
                />
              </Stack>

              {/* Button to open fullscreen */}
              <Box>
                <IconButton onClick={toggleFullscreen} color="primary">
                  {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>

        {!isMobile && (
          <Box
            sx={{
              width: '20%',
              minWidth: drawerWidth,
              backgroundColor: theme.palette.background.default,
              borderColor: theme.palette.divider,
              height: desktopHeight,
              overflow: 'auto',
            }}
          >
            {renderQuestionList()}
          </Box>
        )}

        {isMobile && (
          <Drawer
            sx={{
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: isMobile ? '100%' : drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: theme.palette.background.default,
                border: 'none',
              },
            }}
            variant={isMobile ? 'temporary' : 'persistent'}
            anchor="right"
            open={openNav}
            onClose={handleToggleNavMenu}
          >
            <Toolbar
              disableGutters
              sx={{
                minHeight: '50px !important',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.lighter.main,
                paddingX: {
                  xs: 1,
                },
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {isMobile ? (
                AppBarItems
              ) : (
                <IconButton
                  size="large"
                  aria-label="close navigation"
                  aria-haspopup="true"
                  onClick={handleToggleNavMenu}
                  sx={{
                    color: theme.palette.lighter.main,
                    opacity: 0.6,
                    '&:hover': {
                      opacity: 1,
                    },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Toolbar>
            {renderQuestionList()}
          </Drawer>
        )}
      </Box>
      <AppDialogs />
      <Dialog
        open={showUserForm}
        disableEscapeKeyDown
        aria-labelledby="user-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <LoginDialog isMobile={isMobile} isDarkMode={isDarkMode} />
      </Dialog>
    </Container>
  );
};

export default ExammingPage;
