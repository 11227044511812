// // Question schema
// const QuestionSchema = new Schema({
//   section: {
//     //Section 1, 2, 3
//     type: String,
//     required: true,
//   },
//   type: {
//     type: String,
//     required: true,
//   }, // Multiple choice ABCD named as 'mcq', multiple small checkboxes true/false named as 'mctf',
//   //short answer named as 'sa'
//   correctAnswer: {
//     type: String, // for mcq: A, B, C, D, for mctf: 1001, 1 is true, 0 is false, based on the order of the options
//     //, for sa: the correct answer exapmle: '-2.5'
//     required: true,
//   },
// });

// Updated Question interface based on the Mongoose schema
export interface Question {
  questionId: string; // Unique identifier for the question
  section: string; // Section 1, 2, 3
  sectionContent: string; // Section content
  index: number; // Question number
  type: 'mcq' | 'mctf' | 'sa'; // Question types: Multiple choice ('mcq'), Multiple checkboxes true/false ('mctf'), Short answer ('sa')
  correctAnswer: string; // For mcq: A, B, C, D, for mctf: 1001, for sa: example '-2.5'
  question: string;
  explain: string;
  questionImage: File | null; // File for the question image or resource
  questionImageUrl: string; // URL to the question image or resource
  explainImage: File | null; // File for the answer image or resource
  explainImageUrl: string; // URL to the answer image or resource
  questionA: string; // Option A for MCQ and MCTF
  questionB: string; // Option B for MCQ and MCTF
  questionC: string; // Option C for MCQ and MCTF
  questionD: string; // Option D for MCQ and MCTF
}

export interface SectionType {
  title: string;
  content: string;
  questions: Question[];
}

// // Default objects for different types of questions
// export const defaultQuestionMCQ: Question = {
//   questionId: 'mcq_001', // Example question ID
//   section: 'Phần I', // Dynamically provided section
//   sectionContent: 'Câu trắc nghiệm nhiều phương án lựa chọn.', // Section content
//   index: 1,
//   type: 'mcq',
//   correctAnswer: 'A',
//   question: 'What is the capital of France?',
//   questionImageUrl: '',
//   explainImageUrl: '',
//   questionImage: null,
//   explain: 'The capital of France is Paris.',
//   explainImage: null,
//   questionA: 'Paris',
//   questionB: 'London',
//   questionC: 'Berlin',
//   questionD: 'Madrid',
// };

// export const defaultQuestionMCTF: Question = {
//   questionId: 'mctf_001', // Example question ID
//   section: 'Phần II', // Dynamically provided section
//   sectionContent: 'Câu trắc nghiệm đúng sai.', // Section content
//   index: 1, // Example question index
//   type: 'mctf', // Default question type: Multiple checkboxes true/false
//   correctAnswer: '1101', // Default correct answer for MCTF (true for all options)\
//   question: 'Which of the following are prime numbers?',
//   questionImageUrl: '', // Example question URL
//   explainImageUrl: '', // Example answer URL
//   questionImage: null, // Default question file
//   explainImage: null, // Default answer file
//   questionA: '2', // Default option A
//   questionB: '3', // Default option B
//   questionC: '4', // Default option C
//   questionD: '5', // Default option D
//   explain: '2, 3, 5 are prime numbers.', // Default answer
// };

// export const defaultQuestionSA: Question = {
//   questionId: 'sa_001', // Example question ID
//   section: 'Phần III', // Dynamically provided section
//   sectionContent: 'Câu trắc nghiệm trả lời ngắn. ', // Section content
//   index: 1, // Example question index
//   type: 'sa', // Default question type: Short answer
//   correctAnswer: '-2.5', // Default correct answer for SA
//   question: 'What is the value of -5 + 2.5?',
//   questionImageUrl: '', // Example question URL
//   explainImageUrl: '', // Example answer URL
//   questionImage: null, // Default question file
//   explainImage: null, // Default answer file
//   questionA: '', // Default option A
//   questionB: '', // Default option B
//   questionC: '', // Default option C
//   questionD: '', // Default option D
//   explain: '-2.5', // Default answer
// };

// // Default form for THPT Quốc Gia, including 3 sections
// // Section 1: 18 questions, multiple choice ABCD
// // Section 2: 4 questions, multiple small checkboxes true/false
// // Section 3: 6 questions, short answer
// export const defaultQuestions: Array<Question> = [
//   // Map for Section 1: 18 MCQ questions
//   ...Array(18)
//     .fill(defaultQuestionMCQ)
//     .map((question, index) => ({
//       ...question,
//       index: index + 1, // Index starts from 1 for Section 1
//       section: 'Phần I', // Set section name
//     })),

//   // Map for Section 2: 4 MCTF questions
//   ...Array(4)
//     .fill(defaultQuestionMCTF)
//     .map((question, index) => ({
//       ...question,
//       index: index + 1, // Index starts from 1 for Section 2
//       section: 'Phần II', // Set section name
//     })),

//   // Map for Section 3: 6 Short Answer questions
//   ...Array(6)
//     .fill(defaultQuestionSA)
//     .map((question, index) => ({
//       ...question,
//       index: index + 1, // Index starts from 1 for Section 3
//       section: 'Phần III', // Set section name
//     })),
// ];

export interface Exam {
  examId: string; // Unique identifier for the exam
  courseId: string; // ID of the course to which the exam belongs
  parentFolderId: string; // ID of the parent folder
  title: string; // Title of the exam
  duration: number; // Duration of the exam in minutes
  subject: string; // Subject of the exam
  examTimes: number; // Number of times the exam has been taken
  createdAt?: Date; // Date when the exam was created
  executedAt?: Date; // Date when the exam was executed (optional)
  questions: Array<any>; // Array to hold the questions; replace `any` with the specific type as needed
  createdBy: {
    _id: string;
    name: string;
  };
  isExercise: boolean;
  onlyForMembers: boolean;
}

export interface Result {
  studentId: string;
  examId: string;
  answers: {
    questionId: string;
    answer: string; // 'A', 'B', 'C', 'D' for MCQ, '1001' for MCTF, '-2.5' for SA
  }[];
  completeTime: number; // Time taken to complete the exam in seconds
  name: string; // Student may want to use a different name to hide their identity
  school: string; // Student may want to use a different school to hide their identity
}

export const defaultResult: Result = {
  studentId: '', // ID of the student who took the exam
  examId: '', // ID of the exam
  answers: [], // Array of answers
  completeTime: 0, // Time taken to complete the exam
  name: '', // Student name
  school: '', // Student school
};

export const defaultExam: Exam = {
  examId: '', // This will be generated by the server or can be set manually if needed
  courseId: '', // This will be provided dynamically
  parentFolderId: '', // This will be provided dynamically
  title: 'New Exam', // Default title
  duration: 60, // Default duration in minutes
  subject: 'General', // Default subject
  examTimes: 0, // Default number of times the exam has been taken
  createdAt: new Date(), // Default creation date
  executedAt: new Date(), // Default execution date
  questions: [], // Default questions array
  createdBy: {
    _id: '', // ID of the user who created the exam
    name: '', // Full name of the user who created the exam
  },
  isExercise: false, // Default isExercise value
  onlyForMembers: true, // Default onlyForMembers value
};

export interface ExamInput {
  title: string;
  duration: string;
  subject: string;
  executedAt: Date | null;
  questions: Array<Question>;
  isExercise: boolean;
  onlyForMembers: boolean;
  isEdittingQuestion: boolean;
}

export interface ExammingPageProps {}

export interface CreateExamPageProps {
  exam: Exam;
  mode?: 'view' | 'edit' | 'create';
  examInput: ExamInput;
  setExamInput: React.Dispatch<React.SetStateAction<ExamInput>>;
  isTeacher: boolean;
  isNotOnDialog: boolean;
}

export const defaultExamInput: CreateExamPageProps['examInput'] = {
  title: 'New Exam',
  duration: '60',
  subject: 'Physic',
  executedAt: new Date(),
  questions: [],
  isExercise: false,
  onlyForMembers: true,
  isEdittingQuestion: false,
};
