import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const MODAL = {
  CHANGE_PASSWORD: 'CHANGE_PASSWORD' as 'CHANGE_PASSWORD',
  UPDATE_PROFILE: 'UPDATE_PROFILE' as 'UPDATE_PROFILE',
  LOGIN: 'LOGIN' as 'LOGIN',
  REGISTER: 'REGISTER' as 'REGISTER',
  ADD_TEACHER: 'ADD_TEACHER' as 'ADD_TEACHER',
};
type Modal =
  | 'CHANGE_PASSWORD'
  | 'UPDATE_PROFILE'
  | 'LOGIN'
  | 'REGISTER'
  | 'ADD_TEACHER';

const storedIsDarkMode = localStorage.getItem('isDarkMode') === 'true';

const initialState = {
  timediff: null as number | null,
  showLoginModal: false,
  showLeaderBoardModal: false,
  showRegisterModal: false,
  showUpdateProfileModal: false,
  showUpdateProfileLogout: false,
  showChangePasswordModal: false,
  showAddTeacherModal: false,
  isDarkMode: storedIsDarkMode || false,
  notification: {
    open: false,
    variant: 'success',
    message: 'Hello world',
  },
  linkNotification: {
    open: false,
    message: 'Hello world',
    link: '',
  },
  okCancelNotification: {
    open: false,
    message: 'Hello world',
    isOK: false,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTimeDiff(state, action: PayloadAction<number>) {
      state.timediff = action.payload;
    },
    toggleDarkMode(state) {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem('isDarkMode', state.isDarkMode.toString());
    },
    toggleShowLoginModal(state) {
      state.showLoginModal = !state.showLoginModal;
    },
    toggleShowRegisterModal(state) {
      state.showRegisterModal = !state.showRegisterModal;
    },
    showLoginModal(state) {
      state.showLoginModal = true;
    },
    showNotification(state, action) {
      state.notification = { open: true, ...action.payload };
    },
    hideNotification(state) {
      state.notification = { ...state.notification, open: false };
    },
    showLinkNotification(state, action) {
      state.linkNotification = { open: true, ...action.payload };
    },
    hideLinkNotification(state) {
      state.linkNotification = { ...state.linkNotification, open: false };
    },
    showOKCancelNotification(state, action) {
      state.okCancelNotification = {
        open: true,
        message: action.payload.message,
        isOK: action.payload.isOK,
      };
    },
    hideOKCancelNotification(state, action: PayloadAction<boolean>) {
      state.okCancelNotification = {
        ...state.okCancelNotification,
        open: false,
      };
    },
    toggleShowLeaderBoardModal(state) {
      state.showLeaderBoardModal = !state.showLeaderBoardModal;
    },
    showModal(state, action: PayloadAction<Modal>) {
      switch (action.payload) {
        case MODAL.UPDATE_PROFILE:
          state.showUpdateProfileModal = true;
          break;
        case MODAL.CHANGE_PASSWORD:
          state.showChangePasswordModal = true;
          break;
        case MODAL.LOGIN:
          state.showLoginModal = true;
          break;
        case MODAL.REGISTER:
          state.showRegisterModal = true;
          break;
        case MODAL.ADD_TEACHER:
          state.showAddTeacherModal = true;
          break;

        default:
          break;
      }
    },
    showUpdateProfileWithLogout(state) {
      state.showUpdateProfileModal = true;
      state.showUpdateProfileLogout = true;
    },
    closeUpdateProfileWithLogout(state) {
      state.showUpdateProfileLogout = false;
      state.showUpdateProfileModal = false;
    },
    closeModal(state, action: PayloadAction<Modal>) {
      switch (action.payload) {
        case MODAL.UPDATE_PROFILE:
          state.showUpdateProfileModal = false;
          break;
        case MODAL.CHANGE_PASSWORD:
          state.showChangePasswordModal = false;
          break;
        case MODAL.LOGIN:
          state.showLoginModal = false;
          break;
        case MODAL.REGISTER:
          state.showRegisterModal = false;
          break;
        case MODAL.ADD_TEACHER:
          state.showAddTeacherModal = false;
          break;

        default:
          break;
      }
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice;
