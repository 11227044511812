import React from 'react';
import {
  Typography,
  TextField,
  Paper,
  Button,
  Box,
  List,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Checkbox,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SubjectIcon from '@mui/icons-material/Subject';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TitleIcon from '@mui/icons-material/Title';
import InfoIcon from '@mui/icons-material/Info';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import InfoItem from '../Courses/CourseInfoItem';
import dayjs from 'dayjs';
import useAuth from '../../hooks/useAuth';
import { ExamInput } from './type';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface ExamDetailsProps {
  exam: {
    createdAt?: Date | string;
    executedAt?: Date | string;
  }; // replace with your actual exam type
  mode?: 'view' | 'edit' | 'create';
  examInput: ExamInput;
  setExamInput: React.Dispatch<React.SetStateAction<ExamInput>>;
}

const ExamDetails: React.FC<ExamDetailsProps> = ({
  exam,
  mode = 'view',
  examInput,
  setExamInput,
}) => {
  const { user } = useAuth();
  console.log('examInput', examInput);

  const CeilingUpTime = (newTime: any) => {
    // Round up the minute to 5
    const roundedTime = dayjs(newTime)
      .minute(Math.ceil(newTime.minute() / 5) * 5)
      .second(0)
      .millisecond(0);

    return roundedTime;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    if (name === 'questionURL' && files && files.length > 0) {
      const file = files[0];
      const fileURL = URL.createObjectURL(file);
      setExamInput((prevState) => ({
        ...prevState,
        questionURL: fileURL,
        questionPdf: file,
      }));
    } else {
      setExamInput((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const toggleExecutedAt = () => {
    setExamInput((prevState) => ({
      ...prevState,
      executedAt: prevState.executedAt ? null : new Date(),
    }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Thông tin đề
      </Typography>
      {(user?.role === 'teacher' || user?.role === 'admin') &&
      (mode === 'edit' || mode === 'create') ? (
        <>
          <TextField
            sx={{ marginTop: 0 }}
            label="Title"
            name="title"
            value={examInput.title}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="standard"
            InputProps={{
              startAdornment: <InfoOutlinedIcon style={{ marginRight: 8 }} />,
            }}
          />
          <TextField
            label="Duration (minutes)"
            name="duration"
            value={examInput.duration}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="standard"
            InputProps={{
              startAdornment: <AccessTimeIcon style={{ marginRight: 8 }} />,
            }}
          />
          <FormControl fullWidth margin="normal" variant="standard">
            <InputLabel>Subject</InputLabel>
            <Select
              name="subject"
              value={examInput.subject}
              onChange={(e) => {
                setExamInput((prevState) => ({
                  ...prevState,
                  subject: e.target.value,
                }));
              }}
              startAdornment={<SubjectIcon style={{ marginRight: 8 }} />}
            >
              <MenuItem value="Math">Toán</MenuItem>
              <MenuItem value="Physic">Lý</MenuItem>
              <MenuItem value="Chemical">Hoá</MenuItem>
            </Select>
          </FormControl>

          <FormControl component="fieldset" margin="normal">
            <Typography
              variant="subtitle2"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              LOẠI
              <Tooltip
                title={
                  <>
                    <Typography variant="body2">
                      <strong>"Kiểm tra"</strong>: Mở đề vào thời gian xác định,
                      chỉ được làm một lần duy nhất
                    </Typography>
                    <Typography variant="body2">
                      <strong>"Bài tập"</strong>: Có thể làm lại nhiều lần, hiện
                      hướng dẫn ngay sau khi chọn câu trả lời
                    </Typography>
                  </>
                }
                arrow
              >
                <HelpOutlineIcon
                  style={{ marginLeft: 5, cursor: 'pointer', fontSize: 16 }}
                />
              </Tooltip>
            </Typography>
            <RadioGroup
              row
              name="isExercise"
              value={examInput.isExercise ? 'exercise' : 'test'}
              onChange={(e) => {
                const isExercise = e.target.value === 'exercise';
                setExamInput((prevState) => ({
                  ...prevState,
                  isExercise,
                  // executedAt: isExercise ? null : prevState.executedAt,
                }));
              }}
            >
              <FormControlLabel
                value="test"
                control={<Radio size="small" sx={{ px: 1, pr: 0.5 }} />}
                label={<span style={{ fontSize: '0.9rem' }}>Kiểm tra</span>} // Smaller font size
              />
              <FormControlLabel
                value="exercise"
                control={<Radio size="small" sx={{ px: 1, pr: 0.5 }} />}
                label={<span style={{ fontSize: '0.9rem' }}>Bài tập</span>} // Smaller font size
              />
            </RadioGroup>
          </FormControl>

          <DatePicker
            slotProps={{ textField: { variant: 'standard' } }}
            sx={{
              display: examInput.isExercise ? 'none' : 'block',
            }}
            label="Executed Date"
            value={dayjs(examInput.executedAt)}
            onChange={(newDate) =>
              setExamInput((prevState) => ({
                ...prevState,
                executedAt: newDate ? newDate.toDate() : null,
              }))
            }
          />
          <TimePicker
            slotProps={{ textField: { variant: 'standard' } }}
            sx={{
              marginTop: 3,
              mb: 2,
              display: examInput.isExercise ? 'none' : 'block',
            }}
            label="Executed Time"
            value={CeilingUpTime(dayjs(examInput.executedAt))}
            onChange={(newTime) =>
              setExamInput((prevState) => ({
                ...prevState,
                executedAt: newTime ? newTime.toDate() : null,
              }))
            }
            // onChange={(newTime) => {
            //   if (newTime) {
            //     const roundedTime = dayjs(newTime)
            //       .minute(0)
            //       .second(0)
            //       .millisecond(0);

            //     // If the minute is not exactly 0, round up the hour
            //     if (newTime.minute() > 0) {
            //       setExamInput((prevState) => ({
            //         ...prevState,
            //         executedAt: roundedTime.add(1, 'hour').toDate(),
            //       }));
            //     } else {
            //       setExamInput((prevState) => ({
            //         ...prevState,
            //         executedAt: roundedTime.toDate(),
            //       }));
            //     }
            //   } else {
            //     setExamInput((prevState) => ({
            //       ...prevState,
            //       executedAt: null,
            //     }));
            //   }
            // }}
            ampm={false}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={examInput.onlyForMembers}
                onChange={(e) =>
                  setExamInput((prevState) => ({
                    ...prevState,
                    onlyForMembers: e.target.checked,
                  }))
                }
              />
            }
            label={
              <Typography sx={{ fontSize: '14px' }}>
                {' '}
                {/* Adjust the font size here */}
                Chỉ dành cho thành viên khoá
              </Typography>
            }
          />
          {/* <Button
            variant="outlined"
            color="primary"
            sx={{ marginTop: 2 }}
            onClick={toggleExecutedAt}
          >
            {examInput.executedAt ? 'Disable Time' : 'Enable Time'}
          </Button> */}
        </>
      ) : (
        <Box>
          <List sx={{ py: 0 }}>
            <InfoItem
              icon={<InfoIcon sx={{ fontSize: 18 }} />}
              value={examInput.title}
            />
            <InfoItem
              icon={<AccessTimeIcon sx={{ fontSize: 18 }} />}
              value={`${examInput.duration} minutes`}
            />
            <InfoItem
              icon={<SubjectIcon sx={{ fontSize: 18 }} />}
              value={examInput.subject}
            />
            {/* <CourseInfoItem
              icon={<CalendarTodayIcon sx={{ fontSize: 18 }} />}
              value={
                'Created At: ' + dayjs(exam.createdAt || '').format('D/M/YYYY')
              }
            /> */}
            {examInput.executedAt && (
              <InfoItem
                icon={<CalendarTodayIcon sx={{ fontSize: 18 }} />}
                value={
                  dayjs(exam.executedAt || '').format('D/M/YYYY') +
                  ' - ' +
                  examInput.executedAt.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })
                }
              />
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default ExamDetails;
