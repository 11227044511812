import React from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Stack,
  IconButton,
  ListItemIcon,
  Avatar,
  Menu,
  MenuItem,
  Button,
  Dialog,
  Rating,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PeopleIcon from '@mui/icons-material/People';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale';
import { deleteCourseById } from '../../api';
import { useDispatch } from 'react-redux';
import { deleteCourse } from '../../redux/slices/courseSlice';
import { appActions } from '../../redux/slices/appSlice';
import ReportIcon from '@mui/icons-material/Report';
import CreateIcon from '@mui/icons-material/Create';
import CreateCourseForm from './CreateCourseForm';
import ConfirmDialog from './ComfirmDialog';
import InfoItem from '../Courses/CourseInfoItem';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';

export type CourseProps = {
  courseId: string;
  image: string;
  title: string;
  description: string;
  favorites: number;
  members: number;
  exams: number;
  videos: number;
  pdfs: number;
  createdAt: string;
  lastUpdatedAt: string;
  createdBy: {
    _id: string;
    name: string;
    avatar: string;
  };
  price: number;
  contactLink: string;
  isTeacher?: boolean;
  rates: number[];
};

const CourseCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  borderRadius: 5,
  // boxShadow: theme.shadows[3],
  marginTop: theme.spacing(2),
  position: 'relative',
  cursor: 'pointer',
  backgroundColor: theme.palette.background.default,
  transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition for transform and shadow
  '&:hover, &:focus-within': {
    transform: 'translateY(-10px)', // Move up by 10px
    // boxShadow: theme.shadows[6], // Enhance shadow to give it a floating effect
  },
}));

const CourseImage = styled(CardMedia)(({ theme }) => ({
  borderRadius: 10,
  width: '100%', // Full width
  paddingTop: '56.25%', // Tỷ lệ khung hình 16:9 (100 / (16 / 9))
  position: 'relative', // Định vị để cho phép con absolute
  '& img': {
    position: 'absolute', // Ảnh được định vị tuyệt đối
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Đảm bảo hình ảnh bao phủ khu vực mà vẫn giữ nguyên tỷ lệ
  },
}));

const CourseContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '&:last-child': {
    paddingBottom: 0,
  },
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(1),
}));

const CourseTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  display: '-webkit-box', // Required for limiting lines of text
  WebkitLineClamp: 1, // Limits to 2 lines; you can adjust this number
  WebkitBoxOrient: 'vertical', // Required for multiline ellipsis
  overflow: 'hidden', // Hides the overflowing text
  textOverflow: 'ellipsis', // Adds '...' at the end of the truncated text
  height: 'auto', // Ensures the height adapts to content
  marginRight: theme.spacing(2),
}));

export const WrapText = styled(Typography)(({ theme }) => ({
  display: '-webkit-box', // Required for limiting lines of text
  WebkitLineClamp: 2, // Limits to 2 lines; you can adjust this number
  WebkitBoxOrient: 'vertical', // Required for multiline ellipsis
  overflow: 'hidden', // Hides the overflowing text
  textOverflow: 'ellipsis', // Adds '...' at the end of the truncated text
  height: 'auto', // Ensures the height adapts to content
}));

export const CourseStats = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
}));

export const ListItemIconCustom = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 'small',
}));

export const AdminActions = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(1),
}));

export const CircleButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  borderRadius: '50%', // Làm nút tròn
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const Course: React.FC<CourseProps> = ({
  courseId: id,
  image,
  title,
  description,
  favorites,
  members,
  exams,
  videos,
  pdfs,
  createdAt,
  lastUpdatedAt,
  createdBy,
  price = 0,
  contactLink,
  isTeacher = false,
  rates,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showCreateForm, setShowCreateForm] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isHovering, setIsHovering] = React.useState(false);

  console.log('createdBy:', createdBy);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = () => {
    navigate(`/course/${id}`);
  };

  const handleEdit = () => {
    setShowCreateForm(true);
  };

  const handleDelete = async () => {
    setLoading(true); // Bắt đầu tải

    try {
      await deleteCourseById(id);
      dispatch(deleteCourse(id)); // Gửi hành động xóa khóa học
    } catch (error) {
      console.error('Lỗi khi xóa khóa học:', error);
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Xóa khóa học thất bại',
        })
      );
    } finally {
      setLoading(false); // Dừng tải
      setOpenDialog(false); // Đóng hộp thoại
    }
  };

  const handleCloseForm = () => {
    setShowCreateForm(false);
  };

  // Calculate average rating
  // Calculate average rating
  const totalRate = rates.reduce(
    (total: number, rate: number) => total + rate,
    0
  );

  const averageRate = totalRate / rates.length || 0;
  return (
    <>
      <CourseCard
        onClick={handleClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        elevation={0}
      >
        <CourseImage image={image} title={title}>
          {/* <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              p: 0.5,
              backgroundColor: 'background.paper',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
              color: 'text.secondary',
              textTransform: 'none',
              opacity: 0.5,
              borderRadius: '0 0 0 5px',
              display: isHovering ? 'block' : 'none',
            }}
          >
            <Typography variant="subtitle2" fontSize="small">
              {price
                ? `${new Intl.NumberFormat('vi-VN').format(price)} đ`
                : 'Miễn phí'}
            </Typography>
          </Box> */}
        </CourseImage>
        <CourseContent sx={{ position: 'relative' }}>
          {isTeacher && (
            <AdminActions sx={{ position: 'absolute', top: 4, right: 0 }}>
              <IconButton
                aria-label="more"
                aria-controls="course-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                color="primary"
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>

              <Menu
                id="course-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit();
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIconCustom>
                    <EditIcon fontSize="small" />
                  </ListItemIconCustom>
                  <Typography variant="caption">Edit</Typography>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIconCustom>
                    <DeleteIcon fontSize="small" />
                  </ListItemIconCustom>
                  <Typography variant="caption">Delete</Typography>
                </MenuItem>
              </Menu>
            </AdminActions>
          )}
          <CourseTitle variant="h6">{title}</CourseTitle>
          {/* <WrapText variant="subtitle1">{description}</WrapText> */}
          <Box display="flex" justifyContent="space-between">
            <InfoItem
              icon={<LocalAtmIcon sx={{ fontSize: 18 }} />}
              value={price || 'Miễn phí'}
              label={price ? 'đ' : ''}
              sx={{ marginBottom: 0 }}
              ml={0.5}
            />
            {/* <CourseInfoItem
              variant="subtitle1"
              icon={<StarRoundedIcon sx={{ fontSize: 18 }} />}
              value={averageRate}
              label={`(${rates.length})`}
            /> */}
            <Box display="flex" alignItems="center">
              <Rating
                value={averageRate}
                readOnly
                precision={0.5}
                size="small"
                icon={<StarRoundedIcon fontSize="inherit" />}
                emptyIcon={
                  <StarBorderRoundedIcon
                    fontSize="inherit"
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                }
                sx={{
                  '& .MuiRating-iconFilled': {
                    color: 'prize.first',
                  },
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{
                  color: 'text.secondary',
                }}
              >
                ({rates.length})
              </Typography>
            </Box>
          </Box>
          <Stack direction="row" spacing={1} mt={1}>
            <Avatar src={createdBy.avatar}>
              <AccountCircleIcon />
            </Avatar>
            <Stack direction="column" spacing={0}>
              <CourseStats>
                <Typography
                  variant="subtitle1"
                  sx={{ color: 'text.secondary' }}
                >
                  {createdBy.name}
                </Typography>
              </CourseStats>
              <CourseStats>
                <Stack direction="row" spacing={0}>
                  <InfoItem
                    sx={{
                      marginBottom: 0,
                      color: 'text.secondary',
                      mr: 0.5,
                    }}
                    variant="subtitle1"
                    icon={
                      <PeopleIcon
                        sx={{ fontSize: 18, color: 'text.secondary' }}
                      />
                    }
                    value={members}
                    ml={0.5}
                  />
                  {/* <Typography
                    variant="subtitle1"
                    sx={{
                      color: 'text.secondary',
                      mr: 0.2,
                    }}
                  >
                    {' '}
                    •{' '}
                  </Typography>
                  <CourseInfoItem
                    sx={{
                      marginBottom: 0,
                      color: 'text.secondary',
                      mr: 0.5,
                    }}
                    variant="subtitle1"
                    icon={
                      <StarRoundedIcon
                        sx={{ fontSize: 18, color: 'text.secondary', mb: 0 }}
                      />
                    }
                    value={averageRate}
                    ml={0.2}
                    label={`(${rates.length})`}
                  /> */}

                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: 'text.secondary',
                      overflow: 'hidden', // Hides the overflowing text
                      textOverflow: 'ellipsis', // Adds '...' at the end of the truncated text
                      display: '-webkit-box', // Required for limiting lines of text
                      WebkitLineClamp: 1, // Limits to 2 lines; you can adjust this number
                      WebkitBoxOrient: 'vertical', // Required for multiline ellipsis
                      mr: 0.2,
                    }}
                  >
                    {' '}
                    •{' '}
                    {formatDistanceToNow(new Date(lastUpdatedAt), {
                      addSuffix: true,
                      locale: vi, // Sử dụng tiếng Việt
                    })}
                  </Typography>
                </Stack>
              </CourseStats>
            </Stack>
          </Stack>

          <CourseStats></CourseStats>
        </CourseContent>

        <ConfirmDialog
          open={openDialog}
          onClose={(e) => {
            e.stopPropagation();
            setOpenDialog(false);
          }}
          onConfirm={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
          title="Xóa khóa học"
          content="Bạn có chắc chắn muốn xóa khóa học này không?"
          loading={loading}
        />
      </CourseCard>

      <CreateCourseForm
        onClose={handleCloseForm}
        open={showCreateForm}
        courseToEdit={{
          courseId: id,
          title,
          description,
          image,
          price,
          contactLink,
        }}
      />
    </>
  );
};

export default Course;
