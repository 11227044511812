import React, { useState } from 'react';
import { Box, Stack, Button, Typography } from '@mui/material';
import AddButton from '../Courses/AddButton';

interface CourseHeaderProps {
  onAddCourseClick: () => void;
  onFilterChange: (filter: string) => void;
  isTeacher: boolean;
  filter: string;
}

interface FilterButtonProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({
  label,
  isActive,
  onClick,
}) => (
  <Button
    onClick={onClick}
    variant="contained"
    sx={{
      backgroundColor: isActive ? 'primary.main' : 'background.paper',
      '&:hover': {
        backgroundColor: isActive ? 'primary.dark' : 'primary.light',
      },
      color: isActive ? '' : 'text.primary',
      px: 1.5,
      textTransform: 'none',
    }}
  >
    <Typography variant="subtitle2" fontSize="small">
      {label}
    </Typography>
  </Button>
);

const CourseHeader: React.FC<CourseHeaderProps> = ({
  onAddCourseClick,
  onFilterChange,
  isTeacher,
  filter,
}) => {
  const handleFilterChange = (filter: string) => {
    onFilterChange(filter); // Call the parent function to update the filter
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
      }}
    >
      {/* Left - Filter Buttons */}
      <Stack direction="row" spacing={1}>
        <FilterButton
          label="Tất cả"
          isActive={filter === 'all'}
          onClick={() => handleFilterChange('all')}
        />
        <FilterButton
          label="Miễn phí"
          isActive={filter === 'free'}
          onClick={() => handleFilterChange('free')}
        />
        <FilterButton
          label="Phổ biến"
          isActive={filter === 'popular'}
          onClick={() => handleFilterChange('popular')}
        />
      </Stack>

      {/* Right - Add New Course Button */}
      {isTeacher && (
        <Box>
          <AddButton onClick={onAddCourseClick} />
        </Box>
      )}
    </Box>
  );
};

export default CourseHeader;
