import { light } from '@mui/material/styles/createPalette';

declare module '@mui/material/styles' {
  interface Palette {
    lighter: Palette['primary'];
    digital: Palette['primary'];
    highlightText: Palette['primary'];
    prize: {
      first: string;
      second: string;
      third: string;
      normal: string;
    };
    date: {
      exam: String;
      lesson: String;
    };
    gradient: {
      light: String;
    };
  }
  interface TypeBackground {
    darker: string;
  }
  // allow configuration using `createTheme`
}

const palette = {
  primary: {
    main: '#424242',
    light: '#D1D1D1',
  },
  secondary: {
    main: '#666666',
  },
  text: {
    primary: '#0f0f0f',
    secondary: '#606060',
    disabled: '#909090',
  },
  lighter: {
    main: '#424242',
  },
  prize: {
    first: '#FBC02D', //feb102
    second: '#adbece',
    third: '#e36600',
    normal: '#222222',
  },
  background: {
    default: '#fff',
    paper: '#f1f1f1',
    paperDarker: '#2D2F31',
    darker: '#000000',
  },
  date: {
    exam: '#5a7f8f',
    lesson: '#fe6c85',
  },
  gradient: {
    light: 'linear-gradient(to right, #FCDC93, #f08ad3 75%)',
  },
  digital: {
    main: '#7ed4bd',
  },
  highlightText: {
    main: '#5A7F8F',
  },
};

export default palette;
