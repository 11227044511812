import React from 'react';
import { Avatar, Paper, Stack, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { CourseStats } from '../Home/Course';
import InfoItem from './CourseInfoItem';
import useResponsive from '../../hooks/useResponsive';
import MainButton from './MainButton';

interface CourseDetailsProps {
  courseContent: any;
}

const CourseDetails: React.FC<CourseDetailsProps> = ({ courseContent }) => {
  const isDesktop = useResponsive('up', 'md');
  return (
    <Paper
      elevation={2}
      sx={{
        padding: 3,
        py: 1.5,
        backgroundColor: 'background.paperDarker',
        color: 'background.default',
        borderRadius: 2,
      }}
    >
      <Typography variant="h3" sx={{ mb: 1, color: 'background.default' }}>
        {courseContent.title}
      </Typography>
      <Typography
        variant="body2"
        paragraph
        sx={{ color: 'background.default' }}
      >
        {courseContent.description}
      </Typography>
      <Stack direction="row" spacing={1} mt={1} mb={1}>
        <Avatar src={courseContent.createdBy.avatar}>
          <AccountCircleIcon />
        </Avatar>
        <Stack direction="column" spacing={0}>
          <CourseStats>
            <Typography variant="subtitle2">
              Được tạo bởi {courseContent.createdBy.name}
            </Typography>
          </CourseStats>
          <CourseStats>
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle2">
                Cập nhật lần cuối:{' '}
                {formatDistanceToNow(new Date(courseContent.lastUpdatedAt), {
                  addSuffix: true,
                  locale: vi,
                })}
              </Typography>
            </Stack>
          </CourseStats>
        </Stack>
      </Stack>

      {/* <Typography variant="subtitle2">
      Được tạo bởi {courseContent.createdBy.fullName}
    </Typography>
    <Typography variant="subtitle2" sx={{ mb: 1 }}>
      Cập nhật lần cuối:{' '}
      {formatDistanceToNow(new Date(courseContent.lastUpdatedAt), {
        addSuffix: true,
        locale: vi,
      })}
    </Typography> */}
    </Paper>
  );
};

export default CourseDetails;
