import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  Stack,
  CircularProgress,
  Dialog,
  Paper,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import * as yup from "yup"; // Import yup
import { yupResolver } from "@hookform/resolvers/yup"; // Import yupResolver
import { useForm, SubmitHandler } from "react-hook-form"; // Import useForm
import FormProvider from "../../components/RHF/FormProvider"; // Import FormProvider
import RHFTextField from "../../components/RHF/RHFTextField"; // Import RHFTextField
import { addCourse, updateCourseById } from "../../api";
import { useDispatch } from "react-redux";
import { addCourseAction, updateCourse } from "../../redux/slices/courseSlice";
import useAuth from "../../hooks/useAuth";
import CloseIcon from "@mui/icons-material/Close";

interface CourseFormData {
  courseId: string;
  title: string;
  description: string;
  price: string;
  contactLink: string;
  image?: File | null;
}

interface CreateCourseFormProps {
  onClose: () => void;
  open: boolean;
  courseToEdit?: {
    courseId: string;
    title: string;
    description: string;
    image: string;
    price: number;
    contactLink: string;
  };
}

const CreateCourseForm: React.FC<CreateCourseFormProps> = ({
  onClose,
  open,
  courseToEdit,
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  // Yup schema for form validation
  const schema = yup.object().shape({
    courseId: yup.string().required("Mã Khóa Học là bắt buộc"),
    title: yup.string().required("Tên Khóa Học là bắt buộc"),
    description: yup.string().required("Mô tả là bắt buộc"),
    price: yup
      .string()
      .matches(/^(Free|[0-9]+)$/, "Giá phải là Free hoặc số")
      .required("Giá là bắt buộc"),
    contactLink: yup
      .string()
      .url("Link liên hệ không hợp lệ")
      .required("Link liên hệ là bắt buộc"),
  });

  // Initialize react-hook-form
  const methods = useForm<CourseFormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      courseId: courseToEdit?.courseId || "",
      title: courseToEdit?.title || "",
      description: courseToEdit?.description || "",
      price: courseToEdit?.price?.toString() || "Free",
      contactLink: courseToEdit?.contactLink || "",
    },
  });

  const [imageUrl, setImageUrl] = useState<string>(courseToEdit?.image || "");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (courseToEdit) {
      setImageUrl(courseToEdit.image);
    }
  }, [courseToEdit]);

  // Handle image upload
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmitForm: SubmitHandler<CourseFormData> = async (data) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("courseId", data.courseId);
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("price", data.price === "Free" ? "0" : data.price);
      formData.append("contactLink", data.contactLink);
      formData.append("createdBy", user?._id || "");
      if (imageFile) {
        formData.append("image", imageFile);
      }

      if (courseToEdit) {
        const newCourse = await updateCourseById(data.courseId, formData);
        dispatch(updateCourse({ ...newCourse, createdBy: user }));
      } else {
        const newCourse = await addCourse(formData);
        dispatch(addCourseAction({ ...newCourse, createdBy: user }));
      }
      onClose();
    } catch (error) {
      console.error("Error saving course:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Paper
        sx={{
          padding: 3,
          maxWidth: 360,
        }}
      >
        <Typography variant="h3" component="h2" textAlign="center">
          {courseToEdit ? "Sửa Khóa Học" : "Tạo Khóa Học Mới"}
        </Typography>

        <FormProvider methods={methods} handler={handleSubmitForm}>
          <RHFTextField
            name="courseId"
            label="Mã Khóa Học"
            inputProps={{ style: { textTransform: "uppercase" } }}
            disabled={!!courseToEdit}
          />
          <RHFTextField name="title" label="Tên Khóa Học" />
          <RHFTextField name="description" label="Mô Tả" />
          <RHFTextField name="price" label="Giá" />
          <RHFTextField
            name="contactLink"
            label="Link liên hệ"
            sx={{ mb: 2 }}
          />

          <Stack direction="column" sx={{ mb: 2, textAlign: "center" }}>
            {imageUrl && (
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  maxHeight: "150px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={imageUrl}
                  alt="Đã chọn"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
                <IconButton
                  onClick={() => setImageUrl("")}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                >
                  <Cancel />
                </IconButton>
              </Box>
            )}
            <Button variant="outlined" component="label">
              Chọn Ảnh
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleImageChange}
              />
            </Button>
          </Stack>
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "text.primary",
            }}
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Stack alignItems="center">
            <Button
              variant="contained"
              type="submit"
              sx={{ width: "100%", mb: 2 }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : courseToEdit ? (
                "Cập nhật"
              ) : (
                "Tạo khóa học"
              )}
            </Button>
          </Stack>
        </FormProvider>
      </Paper>
    </Dialog>
  );
};

export default CreateCourseForm;
