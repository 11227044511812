import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Typography,
  Grid,
} from "@mui/material";
import Course from "./Course"; // Import the Course component
import useAuth from "../../hooks/useAuth";
import CreateCourseForm from "./CreateCourseForm";
import { fetchAllCourses } from "../../api"; // Import the fetchAllCourses function
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/index";
import { setCourses } from "../../redux/slices/courseSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorDisplay from "./Error";
import LoadingDisplay from "./Loading";
import CourseHeader from "./CourseHeader";
import paths from "../../constants/paths";
import NotFoundPage from "../Error/NotFoundPage";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const courses = useSelector((state: RootState) => state.course.courses);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState<string>("all");
  const [filteredCourses, setFilteredCourses] = useState(courses);

  const isTeacher =
    location.pathname === paths.MYCOURSE &&
    !!user &&
    (user.role === "admin" || user.role === "teacher");

  // Check if path is /mycourse then filter courses by user id
  useEffect(() => {
    setLoading(true); // Set loading to true immediately

    setTimeout(() => {
      if (isTeacher) {
        setFilteredCourses(
          courses.filter((course) => course.createdBy._id === user._id)
        );
      } else {
        setFilteredCourses(courses);
      }
      setLoading(false); // Set loading to false after the delay
    }, 500); // Ensure loading lasts for at least 0.5 seconds
  }, [dispatch, user, location.pathname, courses, isTeacher]);

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const data = await fetchAllCourses();
        console.log("Courses:", data);
        dispatch(setCourses(data)); // Dispatch setCourses action
      } catch (err) {
        console.error("Error fetching courses:", err);
        setError("Failed to load courses");
      } finally {
        setLoading(false);
      }
    };

    loadCourses();
  }, [dispatch]);

  const handleCreateCourse = () => {
    setShowCreateForm(true);
  };

  const handleCloseForm = () => {
    setShowCreateForm(false);
  };

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter); // Update the filter state
    setLoading(true); // Set loading to true immediately

    setTimeout(() => {
      let filtered;
      if (newFilter === "all") {
        filtered = courses; // Show all courses
      } else if (newFilter === "free") {
        filtered = courses.filter((course) => course.price === 0); // Show free courses
      } else if (newFilter === "popular") {
        filtered = [...courses].sort((a, b) => b.members - a.members); // Create a copy and sort by most members
      }

      filtered && setFilteredCourses(filtered); // Update the filtered courses
      setLoading(false); // Set loading to false after the delay
    }, 500); // Ensure loading lasts for at least 0.5 seconds
  };

  if (
    location.pathname === paths.MYCOURSE &&
    (!user || (user.role !== "admin" && user.role !== "teacher"))
  ) {
    return <NotFoundPage message="Trang chỉ dành cho giáo viên" />;
  }

  return (
    <Container maxWidth={false}>
      <Box sx={{ position: "relative", mb: 4, mt: 2 }}>
        <CourseHeader
          onAddCourseClick={handleCreateCourse}
          onFilterChange={handleFilterChange}
          isTeacher={isTeacher}
          filter={filter}
        />

        {/* {user && (user.role === 'admin' || user.role === 'teacher') && (
          <Button
            variant="contained"
            color="primary"
            sx={{
              position: 'relative',
              top: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              my: 2,
            }}
            onClick={handleCreateCourse}
          >
            Tạo khóa học mới
          </Button>
        )} */}

        <CreateCourseForm onClose={handleCloseForm} open={showCreateForm} />

        {loading && (
          <LoadingDisplay loadingMessage="Đang tải chi tiết khóa học..." />
        )}

        {error && (
          <ErrorDisplay errorMessage="Đã xảy ra lỗi. Vui lòng thử lại sau." />
        )}

        {!loading && !error && (
          <Grid container spacing={3}>
            {filteredCourses.map((course) => (
              <Grid
                item
                xs={12} // Full width on extra small screens
                sm={6} // Half width on small screens and above
                md={4} // One-third width on medium screens and above (adjust as needed)
                lg={4} // One-fourth width on large screens and above (adjust as needed)
                xl={2}
                key={course.courseId}
              >
                <Course
                  courseId={course.courseId}
                  image={course.image}
                  title={course.title}
                  description={course.description}
                  favorites={course.favorites}
                  members={course.members}
                  exams={course.exams}
                  videos={course.videos}
                  pdfs={course.pdfs}
                  createdAt={course.createdAt}
                  lastUpdatedAt={course.lastUpdatedAt}
                  createdBy={course.createdBy}
                  price={course.price}
                  contactLink={course.contactLink}
                  isTeacher={isTeacher}
                  rates={course.rates}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default HomePage;
